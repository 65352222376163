<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>属性管理</el-breadcrumb-item>
      <el-breadcrumb-item>活动特色</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片 -->
    <el-card>
      <!-- 搜索和添加 -->
      <el-row :gutter="20">
        <el-col :span="7">
          <el-input
            placeholder="请输入中文名称"
            v-model="searchQuery.name_cn"
            clearable
            @clear="fetchFeatures"
            size="medium"
          >
            <el-button slot="append" icon="el-icon-search" @click="fetchFeatures"></el-button>
          </el-input>
        </el-col>
        <el-col :span="7">
          <el-input
            placeholder="请输入维语名称"
            v-model="searchQuery.name_ug"
            clearable
            @clear="fetchFeatures"
            size="medium"
          >
            <el-button slot="append" icon="el-icon-search" @click="fetchFeatures"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="showAddDialog = true" size="medium">添加特色</el-button>
        </el-col>
      </el-row>

      <!-- 特色列表 -->
      <el-table :data="featureList" style="width: 100%; margin-top: 20px" border stripe>
        <el-table-column label="#" type="index" width="60" align="center"></el-table-column>
        <el-table-column label="中文名称" prop="name_cn" align="center"></el-table-column>
        <el-table-column label="维语名称" prop="name_ug" align="center"></el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="编辑" placement="top" :enterable="false">
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="editFeature(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="deleteFeature(scope.row.feature_id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        style="margin-top: 20px"
      >
      </el-pagination>
    </el-card>

    <!-- 添加特色对话框 -->
    <el-dialog title="添加特色" :visible.sync="showAddDialog" width="350px" @close="resetForm('addForm')">
      <el-form :model="featureForm" :rules="featureFormRules" ref="addForm" label-width="100px">
        <el-form-item label="中文名称" prop="name_cn">
          <el-input v-model="featureForm.name_cn"></el-input>
        </el-form-item>
        <el-form-item label="维语名称" prop="name_ug">
          <el-input v-model="featureForm.name_ug"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddDialog = false">取 消</el-button>
        <el-button type="primary" @click="addFeature">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑特色对话框 -->
    <el-dialog title="编辑特色" :visible.sync="showEditDialog" width="350px" @close="resetForm('editForm')">
      <el-form :model="featureForm" :rules="featureFormRules" ref="editForm" label-width="100px">
        <el-form-item label="中文名称" prop="name_cn">
          <el-input v-model="featureForm.name_cn"></el-input>
        </el-form-item>
        <el-form-item label="维语名称" prop="name_ug">
          <el-input v-model="featureForm.name_ug"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showEditDialog = false">取 消</el-button>
        <el-button type="primary" @click="updateFeature">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Features',
  data() {
    return {
      // 特色列表数据
      featureList: [],
      // 搜索参数
      searchQuery: {
        name_cn: '',
        name_ug: ''
      },
      // 分页参数
      total: 0,
      pageNum: 1,
      pageSize: 10,
      // 对话框显示控制
      showAddDialog: false,
      showEditDialog: false,
      // 表单数据
      featureForm: {
        name_cn: '',
        name_ug: ''
      },
      // 表单验证规则
      featureFormRules: {
        name_cn: [
          { required: true, message: '请输入特色中文名称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
        name_ug: [
          { required: true, message: '请输入特色维语名称', trigger: 'blur' },
          { min: 2, max: 80, message: '长度在 2 到 80 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created() {
    this.fetchFeatures()
  },
  methods: {
    fetchFeatures() {
      const params = {
        page: this.pageNum,
        size: this.pageSize,
        ...this.searchQuery
      }
      this.$http({ method: 'get', url: 'features', params })
        .then(res => {
          if (!res.success) return this.$message.error(res.message)
          this.featureList = res.data
          this.total = res.total
        })
        .catch(err => {
          this.$message.error(err.response?.data?.detail || '获取特色列表失败')
        })
    },
    addFeature() {
      this.$refs.addForm.validate(valid => {
        if (!valid) return
        this.$http({ method: 'post', url: 'features', data: this.featureForm })
          .then(res => {
            if (!res.success) return this.$message.error(res.message)
            this.$message.success('添加特色成功')
            this.showAddDialog = false
            this.fetchFeatures()
          })
          .catch(err => {
            this.$message.error(err.response?.data?.detail || '添加特色失败')
          })
      })
    },
    editFeature(row) {
      this.featureForm = { ...row }
      this.showEditDialog = true
    },
    updateFeature() {
      this.$refs.editForm.validate(valid => {
        if (!valid) return
        this.$http({ method: 'put', url: `features/${this.featureForm.feature_id}`, data: this.featureForm })
          .then(res => {
            if (!res.success) return this.$message.error(res.message)
            this.$message.success('更新特色成功')
            this.showEditDialog = false
            this.fetchFeatures()
          })
          .catch(err => {
            this.$message.error(err.response?.data?.detail || '更新特色失败')
          })
      })
    },
    deleteFeature(id) {
      this.$confirm('此操作将永久删除该特色, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http({ method: 'delete', url: `features/${id}` })
            .then(res => {
              if (!res.success) return this.$message.error(res.message)
              this.$message.success('删除特色成功')
              this.fetchFeatures()
            })
            .catch(err => {
              this.$message.error(err.response?.data?.detail || '删除特色失败')
            })
        })
        .catch(() => {})
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.featureForm = {
        name_cn: '',
        name_ug: ''
      }
    },
    handleSizeChange(newSize) {
      this.pageSize = newSize
      this.fetchFeatures()
    },
    handleCurrentChange(newPage) {
      this.pageNum = newPage
      this.fetchFeatures()
    }
  }
}
</script>

<style scoped>
.el-card {
  margin-top: 15px;
}

.el-input :deep(.el-input__inner) {
  font-family: inherit;
}
</style>