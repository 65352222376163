<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>属性管理</el-breadcrumb-item>
      <el-breadcrumb-item>标签列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片 -->
    <el-card>
      <!-- 搜索和添加 -->
      <el-row :gutter="20">
        <el-col :span="7">
          <el-input
            placeholder="请输入中文名称"
            v-model="searchQuery.name_cn"
            clearable
            @clear="fetchTags"
            size="medium"
          >
            <el-button slot="append" icon="el-icon-search" @click="fetchTags"></el-button>
          </el-input>
        </el-col>
        <el-col :span="7">
          <el-input
            placeholder="请输入维语名称"
            v-model="searchQuery.name_ug"
            clearable
            @clear="fetchTags"
            size="medium"
          >
            <el-button slot="append" icon="el-icon-search" @click="fetchTags"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="showAddDialog = true" size="medium">添加标签</el-button>
        </el-col>
      </el-row>

      <!-- 标签列表 -->
      <el-table :data="tagList" style="width: 100%; margin-top: 20px" border stripe>
        <el-table-column label="#" type="index" width="60" align="center"></el-table-column>
        <el-table-column label="中文名称" prop="name_cn" align="center"></el-table-column>
        <el-table-column label="维语名称" prop="name_ug" align="center"></el-table-column>
        <el-table-column label="颜色" prop="color" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.color === 'red' ? 'danger' : scope.row.color === 'blue' ? 'primary' : 'success'">
              {{ scope.row.color === 'red' ? '红色' : scope.row.color === 'blue' ? '蓝色' : '绿色' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="编辑" placement="top" :enterable="false">
              <el-button
                type="primary"
                icon="el-icon-edit"
                size="mini"
                @click="editTag(scope.row)"
              ></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除" placement="top" :enterable="false">
              <el-button
                type="danger"
                icon="el-icon-delete"
                size="mini"
                @click="deleteTag(scope.row.tag_id)"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNum"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        style="margin-top: 20px"
      >
      </el-pagination>
    </el-card>

    <!-- 添加标签对话框 -->
    <el-dialog title="添加标签" :visible.sync="showAddDialog" width="350px" @close="resetForm('addForm')">
      <el-form :model="tagForm" :rules="tagFormRules" ref="addForm" label-width="100px">
        <el-form-item label="中文名称" prop="name_cn">
          <el-input v-model="tagForm.name_cn"></el-input>
        </el-form-item>
        <el-form-item label="维语名称" prop="name_ug">
          <el-input v-model="tagForm.name_ug"></el-input>
        </el-form-item>
        <el-form-item label="颜色" prop="color">
          <el-select v-model="tagForm.color" placeholder="请选择颜色">
            <el-option label="红色" value="red"></el-option>
            <el-option label="蓝色" value="blue"></el-option>
            <el-option label="绿色" value="green"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showAddDialog = false">取 消</el-button>
        <el-button type="primary" @click="addTag">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 编辑标签对话框 -->
    <el-dialog title="编辑标签" :visible.sync="showEditDialog" width="350px" @close="resetForm('editForm')">
      <el-form :model="tagForm" :rules="tagFormRules" ref="editForm" label-width="100px">
        <el-form-item label="中文名称" prop="name_cn">
          <el-input v-model="tagForm.name_cn"></el-input>
        </el-form-item>
        <el-form-item label="维语名称" prop="name_ug">
          <el-input v-model="tagForm.name_ug"></el-input>
        </el-form-item>
        <el-form-item label="颜色" prop="color">
          <el-select v-model="tagForm.color" placeholder="请选择颜色">
            <el-option label="红色" value="red"></el-option>
            <el-option label="蓝色" value="blue"></el-option>
            <el-option label="绿色" value="green"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showEditDialog = false">取 消</el-button>
        <el-button type="primary" @click="updateTag">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'Tags',
  data() {
    return {
      // 标签列表数据
      tagList: [],
      // 搜索参数
      searchQuery: {
        name_cn: '',
        name_ug: ''
      },
      // 分页参数
      total: 0,
      pageNum: 1,
      pageSize: 10,
      // 对话框显示控制
      showAddDialog: false,
      showEditDialog: false,
      // 表单数据
      tagForm: {
        name_cn: '',
        name_ug: '',
        color: ''
      },
      // 表单验证规则
      tagFormRules: {
        name_cn: [
          { required: true, message: '请输入标签中文名称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
        name_ug: [
          { required: true, message: '请输入标签维语名称', trigger: 'blur' },
          { min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
        ],
        color: [
          { required: true, message: '请选择颜色', trigger: 'change' }
        ]
      }
    }
  },
  created() {
    this.fetchTags()
  },
  methods: {
    fetchTags() {
      const params = {
        page: this.pageNum,
        size: this.pageSize,
        ...this.searchQuery
      }
      this.$http({ method: 'get', url: 'tags', params })
        .then(res => {
          if (!res.success) return this.$message.error(res.message)
          this.tagList = res.data
          this.total = res.total
        })
        .catch(err => {
          this.$message.error(err.response?.data?.detail || '获取标签列表失败')
        })
    },
    addTag() {
      this.$refs.addForm.validate(valid => {
        if (!valid) return
        this.$http({ method: 'post', url: 'tags', data: this.tagForm })
          .then(res => {
            if (!res.success) return this.$message.error(res.message)
            this.$message.success('添加标签成功')
            this.showAddDialog = false
            this.fetchTags()
          })
          .catch(err => {
            this.$message.error(err.response?.data?.detail || '添加标签失败')
          })
      })
    },
    editTag(row) {
      this.tagForm = { ...row }
      this.showEditDialog = true
    },
    updateTag() {
      this.$refs.editForm.validate(valid => {
        if (!valid) return
        this.$http({ method: 'put', url: `tags/${this.tagForm.tag_id}`, data: this.tagForm })
          .then(res => {
            if (!res.success) return this.$message.error(res.message)
            this.$message.success('更新标签成功')
            this.showEditDialog = false
            this.fetchTags()
          })
          .catch(err => {
            this.$message.error(err.response?.data?.detail || '更新标签失败')
          })
      })
    },
    deleteTag(id) {
      this.$confirm('此操作将永久删除该标签, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http({ method: 'delete', url: `tags/${id}` })
            .then(res => {
              if (!res.success) return this.$message.error(res.message)
              this.$message.success('删除标签成功')
              this.fetchTags()
            })
            .catch(err => {
              this.$message.error(err.response?.data?.detail || '删除标签失败')
            })
        })
        .catch(() => {})
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
      this.tagForm = {
        name_cn: '',
        name_ug: '',
        color: ''
      }
    },
    handleSizeChange(newSize) {
      this.pageSize = newSize
      this.fetchTags()
    },
    handleCurrentChange(newPage) {
      this.pageNum = newPage
      this.fetchTags()
    }
  }
}
</script>

<style scoped>
.el-card {
  margin-top: 15px;
}

.el-input :deep(.el-input__inner) {
  font-family: inherit;
}
</style>