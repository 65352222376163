<template>
  <div class="wrap">
    <div class="form">
      <div class="content">
        <div class="logo">
          <img src="../assets/artlens.jpg" alt="Art-Lens Logo">
        </div>
        <h1>Art-Lens CMS</h1>
        <p>Welcome to Art-Lens Content Management System. Here you can manage your contents for Art-Lens WeChat Mini Program.</p>
        <div class="cta-buttons">
          <button class="primary" @click="guidance">操作指南</button>
          <button class="secondary" @click="personal">个人中心</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'Welcome',
    methods: {
        guidance() {
            this.$router.push('/admin/guide');
        },
        personal() {
            this.$router.push('/admin/aboutme');
        }
    }
}
</script>

<style scoped>
.wrap {
    width: 100%;
    height: 100%;
    margin: -20px;
    padding: 20px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background-image: linear-gradient(135deg, #373d41, #4a5064, #373d41);
}

.form {
    width: 840px;
    height: 560px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    box-shadow: 0 25px 45px rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.content {
    text-align: center;
    color: #fff;
    padding: 0 40px;
}

.logo {
    margin-bottom: 20px;
}

.logo img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 3px solid rgba(255, 255, 255, 0.2);
    padding: 5px;
}

.content h1 {
    font-size: 48px;
    margin-bottom: 20px;
    font-weight: 700;
    text-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.content p {
    font-size: 18px;
    line-height: 1.6;
    margin-bottom: 40px;
    max-width: 600px;
    color: rgba(255, 255, 255, 0.9);
}

.cta-buttons {
    display: flex;
    gap: 20px;
    justify-content: center;
}

button {
    padding: 15px 40px;
    border-radius: 30px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 8px;
}

.primary {
    background: #409eff;
    color: #fff;
    border: none;
}

.primary:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(64, 158, 255, 0.4);
    background: #66b1ff;
}

.secondary {
    background: transparent;
    color: #fff;
    border: 2px solid rgba(255, 255, 255, 0.8);
}

.secondary:hover {
    background: rgba(255, 255, 255, 0.1);
    transform: translateY(-2px);
    border-color: #fff;
}

[class^="el-icon-"] {
    font-size: 20px;
}
</style>