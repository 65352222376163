<template>
  <div class="public-container">
    <!-- Hero Section -->
    <section class="hero">
      <div class="hero-particles" id="particles-js"></div>
      <div class="hero-content">
        <div class="hero-text">
          <div class="brand-container">
            <div class="brand-logo">
              <img src="@/assets/artlens.jpg" alt="ArtLens Logo" />
            </div>
            <div class="brand-name">
              <span class="text-gradient">Art-Lens</span>
              <span class="text-gradient">上海阿特兰斯</span>
            </div>
          </div>
          <div class="tagline">
            <div class="typing-text" ref="typingText"></div>
          </div>
        </div>
      </div>
      <div class="hero-decorations">
        <!-- 添加装饰元素 -->
        <div class="decoration-circle circle-1"></div>
        <div class="decoration-circle circle-2"></div>
      </div>
    </section>

    <!-- Company Introduction -->
    <section class="company-intro">
      <h2>公司简介</h2>
      <div class="intro-content">
        <div class="intro-text">
          <div class="intro-text-container">
            <div class="intro-paragraph">
              <div class="quote-mark left">❝</div>
              <p>上海阿特兰斯文化传播有限公司自创立之初，就秉借其独到的文化愿景和创新性的创新思维。在大型文艺比赛、青少年经验分享会以及公益活动的策划与执行领域崭露头角。公司凭借卓越的组织能力和对文化多样性的深刻理解，不仅获得了广大青年的热情参与，也赢得了社会各界的高度评价和广泛赞誉。</p>
            </div>
            <div class="intro-paragraph">
              <p>阿特兰斯文化始终坚守创新、坚毅、服务的核心价值观。我们致力于挖掘和传承民族文化，同积极探索文化创新的新途径，为新疆各民族青少年提供丰富多样的文化体验和学习机会。</p>
              <div class="quote-mark right">❞</div>
            </div>
          </div>
          <div class="intro-highlights">
            <div class="highlight-item">
              <div class="highlight-icon">🎯</div>
              <span class="highlight-text">创新思维</span>
            </div>
            <div class="highlight-item">
              <div class="highlight-icon">🤝</div>
              <span class="highlight-text">文化传承</span>
            </div>
            <div class="highlight-item">
              <div class="highlight-icon">💫</div>
              <span class="highlight-text">青年发展</span>
            </div>
          </div>
        </div>
        <div class="intro-image">
          <div class="image-wrapper">
            <div class="image-frame">
              <img src="@/assets/artlens.jpg" alt="Company Image" />
              <div class="frame-decoration top-left"></div>
              <div class="frame-decoration top-right"></div>
              <div class="frame-decoration bottom-left"></div>
              <div class="frame-decoration bottom-right"></div>
            </div>
            <div class="image-overlay"></div>
          </div>
        </div>
      </div>
    </section>

    <!-- Founder and Team -->
    <section class="team">
      <h2>创始人及团队介绍</h2>
      <div class="founder">
        <div class="founder-card">
          <div class="founder-image">
            <img src="@/assets/artlens.jpg" alt="Founder" />
          </div>
          <div class="founder-info">
            <span class="founder-role">创始人 / 执行总监</span>
            <h3>帕哈尔丁·帕尔哈提</h3>
            <p class="founder-education">俄罗斯太平洋大学文学交流运动员双学位学士</p>
            <div class="founder-quote">
              <i class="quote-mark">❝</i>
              <p>致力于为青少年创造优质的文化交流平台，让每一位年轻人都能找到自己的人生方向。</p>
            </div>
          </div>
        </div>
      </div>

      <div class="team-container">
        <div class="team-grid">
          <div class="team-member" v-for="member in teamMembers" :key="member.name">
            <div class="member-image">
              <img :src="member.image" :alt="member.name" />
            </div>
            <div class="member-info">
              <h4>{{ member.name }}</h4>
              <p>{{ member.title }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Special Activities -->
    <section class="activities">
      <h2>特色活动介绍</h2>
      <div class="activities-grid">
        <div class="activity-card academic">
          <div class="activity-images">
            <div class="image-grid">
              <img v-for="(img, index) in academicImages" 
                   :key="index" 
                   :src="img" 
                   :alt="'Academic Image ' + (index + 1)"
                   @click="showLightbox(img)" />
            </div>
          </div>
          <div class="activity-info">
            <h3>学术探索，进入名校</h3>
            <p>阿特兰斯游学营员们进入到复旦大学、清华大学、华东师范大学、上海外国语大学化基地</p>
          </div>
        </div>

        <div class="activity-card course">
          <div class="activity-info">
            <h3>阿特兰斯专属课程</h3>
            <p>一场将学习与探索完美融合的知识之旅</p>
            <ul class="course-features">
              <li>个性化学习规划</li>
              <li>专业导师指导</li>
              <li>实践与理论结合</li>
              <li>跨文化交流体验</li>
            </ul>
          </div>
        </div>
      </div>
    </section>

    <!-- Partners Carousel -->
    <section class="partners">
      <h2>合作平台、机构</h2>
      <div class="partners-carousel" ref="partnersCarousel">
        <div class="carousel-container" :style="{ transform: 'translateX(' + carouselPosition + 'px)' }">
          <img v-for="(partner, index) in partners" 
               :key="index" 
               :src="partner" 
               :alt="'Partner ' + (index + 1)" />
        </div>
      </div>
      <div class="background-decoration"></div>
    </section>

    <!-- Vision and Mission -->
    <section class="vision-mission">
      <h2>愿景与使命</h2>
      <div class="vision-grid">
        <div class="vision-card">
          <div class="vision-icon">🌏</div>
          <h3>市场拓展</h3>
          <p>随着全球化的加速推进，阿特兰斯也将积极开拓国内外市场，通过精细化市场分析，我们将针对不同目标市场制定个性化的市场拓展策略。</p>
        </div>
        <div class="vision-card">
          <div class="vision-icon">💡</div>
          <h3>产品创新</h3>
          <p>游学是阿特兰斯发展的核心。未来，我们将持续投入研发力量，围绕客户需求进行产品创新。通过引入先进的教育理念、独特的文化体验和科学的课程设计，打造出更具吸引力、更具价值的游学产品，以满足客户的多样化需求。</p>
        </div>
        <div class="vision-card">
          <div class="vision-icon">🤝</div>
          <h3>合作发展</h3>
          <p>面对复杂多变的市场环境，合作发展是公司的重要策略。未来，我们将积极寻求与国内外知名机构、企业的合作机会，通过资源共享、优势互补等方式，实现共同发展。同时，我们将加强与其他游学文化公司的交流与合作，共同推动游学行业的繁荣与进步。</p>
        </div>
        <div class="vision-card">
          <div class="vision-icon">❤️</div>
          <h3>社会责任</h3>
          <p>作为一家有社会责任感的企业，我们将积极履行社会责任。通过参与公益事业、支持教育事业等方式，回馈社会、关爱弱势群体。同时，作为教育领域的参与者，我们深知负的责任与使命，将积极投入资源，为边疆教育事业贡献力量。</p>
        </div>
      </div>
    </section>

    <!-- Contact QR Codes -->
    <section class="contact">
      <h2>联系我们</h2>
      <div class="qr-grid">
        <div class="qr-code" v-for="qr in qrCodes" :key="qr.title">
          <img :src="qr.image" :alt="qr.title" />
          <p>{{ qr.title }}</p>
        </div>
      </div>
    </section>

    <!-- Thank You & Footer -->
    <section class="thank-you-footer">
      <div class="content-wrapper">
        <!-- Thank You Part -->
        <div class="thanks-container">
          <div class="thanks-grid">
            <div class="thanks-item" v-for="(thanks, index) in thanksMessages" :key="index">
              <div class="thanks-bubble">
                {{ thanks }}
              </div>
            </div>
          </div>
        </div>

        <!-- Footer Part -->
        <div class="footer-container">
          <div class="footer-content">
            <div class="company-info">
              <img src="@/assets/artlens.jpg" alt="ArtLens Logo" class="footer-logo"/>
              <div class="company-details">
                <h4>上海阿特兰斯文化传播有限公司</h4>
                <p>创新思维 | 文化传承 | 青年发展</p>
              </div>
            </div>
            <div class="copyright">
              <p>© 2024 上海阿特兰斯文化传播有限公司</p>
              <p>版权所有 | 沪ICP备xxxxxxxx-1</p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- Admin Entry -->
    <div class="admin-entry">
      <router-link to="/admin" class="admin-button">
        <div class="admin-icon">⚙️</div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Public',
  data() {
    return {
      carouselPosition: 0,
      teamMembers: [
        { name: '米合古丽·艾孜孜', image: require('@/assets/artlens.jpg'), title: '阿特兰斯执行董事成员' },
        { name: '艾克赛·卡门', image: require('@/assets/artlens.jpg'), title: '阿特兰斯执行董事成员' },
        { name: '努尔古丽', image: require('@/assets/artlens.jpg'), title: '阿特兰斯执行董���成员' }
      ],
      activities: [
        {
          title: '学术探索，进入名校',
          image: require('@/assets/artlens.jpg'),
          description: '阿特兰斯游学营员们进入到复旦大学、清华大学、华东师范大学、上海外国语大学化基地'
        },
        {
          title: '阿特兰斯专属课程',
          image: require('@/assets/artlens.jpg'),
          description: '一场将学习与探索完美融合的知识之旅'
        }
      ],
      partners: Array(10).fill(require('@/assets/artlens.jpg')),
      qrCodes: [
        { title: '公众号二维码', image: require('@/assets/artlens.jpg') },
        { title: '客服微信', image: require('@/assets/artlens.jpg') },
        { title: '官方二维码', image: require('@/assets/artlens.jpg') }
      ],
      academicImages: [
        require('@/assets/artlens.jpg'),
        require('@/assets/artlens.jpg'),
        require('@/assets/artlens.jpg'),
        require('@/assets/artlens.jpg')
      ],
      thanksMessages: [
        '谢谢!',
        'رەھمت!',
        'Thank you!',
        'Спасибо!',
        'Danke!',
        'Merci!',
        'Teşekkürler!',
        'Gracias!'
      ],
    }
  },
  mounted() {
    this.startCarousel()
    this.initParticles()
    this.initTypingEffect()
    
    // 添加滚动动画
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    }, {
      threshold: 0.1
    });

    document.querySelectorAll('section').forEach(section => {
      section.classList.add('fade-in');
      observer.observe(section);
    });
  },
  methods: {
    startCarousel() {
      setInterval(() => {
        this.carouselPosition -= 2;
        const totalWidth = this.partners.length * 240;
        if (Math.abs(this.carouselPosition) >= totalWidth) {
          this.carouselPosition = 0;
        }
      }, 30);
    },
    initParticles() {
      require('particles.js')
      particlesJS('particles-js', {
        particles: {
          number: {
            value: 100,
            density: {
              enable: true,
              value_area: 800
            }
          },
          color: {
            value: "#ffffff"
          },
          shape: {
            type: "circle"
          },
          opacity: {
            value: 0.3,
            random: true,
            animation: {
              enable: true,
              speed: 1,
              opacity_min: 0.1,
              sync: false
            }
          },
          size: {
            value: 3,
            random: true,
            animation: {
              enable: true,
              speed: 2,
              size_min: 0.1,
              sync: false
            }
          },
          line_linked: {
            enable: true,
            distance: 150,
            color: "#00A8E8",
            opacity: 0.2,
            width: 1
          },
          move: {
            enable: true,
            speed: 1,
            direction: "none",
            random: true,
            straight: false,
            out_mode: "out",
            bounce: false,
          }
        },
        interactivity: {
          detect_on: "canvas",
          events: {
            onhover: {
              enable: true,
              mode: "grab"
            },
            onclick: {
              enable: true,
              mode: "push"
            },
            resize: true
          },
          modes: {
            grab: {
              distance: 140,
              line_linked: {
                opacity: 0.5
              }
            },
            push: {
              particles_nb: 4
            }
          }
        },
        retina_detect: true
      })
    },
    initTypingEffect() {
      const texts = [
        '探索未知领域 做最耀的自己',
        'ئىلىم ئوكيانىدا ئۈزۈپ، ئەڭ يېقىن ئۆزۈمگە يېىمەن'
      ]
      let currentIndex = 0
      let isTyping = true
      
      const typeText = async (text) => {
        if (!this.$refs.typingText) return false
        const element = this.$refs.typingText
        
        try {
          // Clear with fade out effect
          await new Promise(resolve => {
            element.style.opacity = '0'
            setTimeout(resolve, 300)
          })
          element.textContent = ''
          element.style.opacity = '1'
          
          // Type each character with random delay for natural feel
          for (let char of text) {
            if (!isTyping || !this.$refs.typingText) return false
            element.textContent += char
            // Random delay between 40-100ms for more natural typing
            await new Promise(resolve => setTimeout(resolve, 40 + Math.random() * 60))
          }
          
          // Keep text visible for longer
          await new Promise(resolve => setTimeout(resolve, 2000))
          
          // Smoothly delete each character
          for (let i = text.length; i >= 0; i--) {
            if (!isTyping || !this.$refs.typingText) return false
            element.textContent = text.substring(0, i)
            // Random delay between 40-100ms for more natural deleting
            await new Promise(resolve => setTimeout(resolve, 40 + Math.random() * 60))
          }

          // Fade out after deletion
          await new Promise(resolve => {
            element.style.transition = 'opacity 0.5s ease'
            element.style.opacity = '0'
            setTimeout(resolve, 500)
          })
          element.textContent = ''

          return true
        } catch (error) {
          console.error('Typing effect error:', error)
          return false
        }
      }

      const loop = async () => {
        await this.$nextTick()
        
        while (isTyping && this.$refs.typingText) {
          try {
            const success = await typeText(texts[currentIndex])
            if (success) {
              currentIndex = (currentIndex + 1) % texts.length
            } else {
              await new Promise(resolve => setTimeout(resolve, 1000))
            }
          } catch (error) {
            console.error('Typing loop error:', error)
            await new Promise(resolve => setTimeout(resolve, 1000))
          }
        }
      }

      this.$nextTick(() => {
        if (this.$refs.typingText) {
          this.$refs.typingText.style.transition = 'opacity 0.3s ease'
          loop()
        }
      })

      this.$once('hook:beforeDestroy', () => {
        isTyping = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
// 定义通用变量
@primary-gradient: linear-gradient(90deg, #c17f59, #e2b396);
@box-shadow-default: 0 15px 35px rgba(0,0,0,0.05);
@box-shadow-hover: 0 25px 45px rgba(0,0,0,0.1);
@transition-default: all 0.3s ease;
@primary-color: #c17f59;
@secondary-color: #e2b396;
@text-primary: #2c3e50;
@text-secondary: #666;
@spacing-base: 24px;
@section-padding: 120px;
@mobile-section-padding: 80px;

// 定义基础变量
@section-spacing: 120px;  // section之间的间距
@section-spacing-mobile: 80px;
@container-padding: 40px;  // 容器左右padding
@container-padding-mobile: 20px;

// 定义深色主题变量
@dark-bg-primary: #1a1a2e;
@dark-bg-secondary: #16213e;
@dark-text-primary: rgba(255, 255, 255, 0.9);
@dark-text-secondary: rgba(255, 255, 255, 0.7);
@dark-border: rgba(255, 255, 255, 0.1);
@dark-card-bg: rgba(255, 255, 255, 0.05);

// 通用卡片样式 Mixin
.card-base() {
  background: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 20px;
  padding: @spacing-base * 1.5;
  box-shadow: 0 15px 35px rgba(0,0,0,0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 25px 45px rgba(0,0,0,0.3);
    border-color: rgba(255, 255, 255, 0.2);
  }
}

// 通用标题样式 Mixin
.section-title() {
  text-align: center;
  font-size: 42px;
  margin-bottom: @spacing-base * 3;
  color: #fff;
  position: relative;
  font-weight: 300;
  letter-spacing: 2px;
  
  &:after {
    content: '';
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 4px;
    background: linear-gradient(90deg, rgba(255,255,255,0.2), rgba(255,255,255,0.1));
    border-radius: 2px;
  }
}

.section-container() {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0;
  width: 100%;
  
  @media (max-width: 768px) {
    padding: 0;
  }
}

.public-container {
  width: 100%;
  overflow-x: hidden;
  // 移除深色背景渐变
  background: #fff;
  color: #333;
  position: relative;

  // 移除全局背景装饰
  &::before {
    display: none;
  }

  section {
    position: relative;
    z-index: 1;

    h2 {
      .section-title();
      // 更新标题颜色为深色
      color: #2c3e50;
      
      &:after {
        // 更新下划线样式
        background: linear-gradient(90deg, #c17f59, #e2b396);
      }
    }

    // 移除之前的倾斜背景
    &::before {
      display: none;
    }

    &:hover::after {
      opacity: 1;
    }

    padding: @section-spacing 0;
    .section-container();
    
    @media (max-width: 768px) {
      padding: @section-spacing-mobile 0;
    }

    // 为相邻的section添加间距
    & + section {
      margin-top: @section-spacing;
      
      @media (max-width: 768px) {
        margin-top: @section-spacing-mobile;
      }
    }
  }

  // Hero section 例外处理
  .hero {
    padding: 0 !important;
    margin: 0 !important;
    max-width: none;
    width: 100%;
    height: 90vh;
    min-height: 600px;
    max-height: 900px;
    
    &::before {
      display: none;
    }
  }
}

.hero {
  height: 100vh;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    background: radial-gradient(
      circle at center,
      rgba(100,255,218,0.1) 0%,
      transparent 70%
    );
  }

  .hero-particles {
    position: absolute;
    inset: 0;
    z-index: 1;
  }

  .hero-content {
    position: relative;
    z-index: 2;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    animation: fadeIn 1s ease;

    .hero-text {
      text-align: center;
      text-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
      animation: fadeInUp 1s ease;

      .brand-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        margin-bottom: 4rem;

        .brand-logo {
          margin-bottom: 2.5rem;
          
          img {
            width: 150px;
            height: 150px;
            border: 4px solid rgba(100,255,218,0.15);
            border-radius: 50%;
            transition: all 0.3s ease;
            
            &:hover {
              transform: scale(1.05);
              border-color: rgba(100,255,218,0.4);
            }
          }
        }

        .brand-name {
          .text-gradient {
            display: block;
            font-weight: 200;
            letter-spacing: 2px;
            color: #ffffff;
            text-shadow: 0 2px 15px rgba(0,0,0,0.3);
            
            &:not(.chinese) {
              font-size: 4.2rem;
              margin-bottom: 1rem;
            }
            
            &.chinese {
              font-size: 2.8rem;
              opacity: 0.85;
            }
          }
        }
      }

      .tagline {
        margin-top: 4rem;
        
        .typing-text {
          font-size: 1.8rem;
          color: rgba(255, 255, 255, 0.9);
          font-weight: 200;
          letter-spacing: 2px;
          line-height: 1.8;
          min-height: 3.2rem;
          text-shadow: 0 2px 10px rgba(0,0,0,0.3);
          transition: opacity 0.3s ease;
          
          &:empty {
            opacity: 0;
          }
        }
      }
    }
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.company-intro {
  background: #fff;
  position: relative;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -20%;
    width: 140%;
    height: 100%;
    background: linear-gradient(45deg, rgba(193, 127, 89, 0.02) 0%, rgba(226, 179, 150, 0.02) 100%);
    transform: skewY(-5deg);
    z-index: -1;
  }

  .intro-content {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
    gap: 80px;
    align-items: center;
    
    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
      gap: 60px;
    }

    .intro-text {
      .intro-text-container {
        position: relative;
        padding: 40px;
        background: transparent;
        border-radius: 30px;
        box-shadow: none;
        
        .intro-paragraph {
          position: relative;
          margin-bottom: 30px;
          
          &:last-child {
            margin-bottom: 0;
          }
          
          p {
            font-size: 17px;
            line-height: 2;
            color: #2c3e50;
            text-align: justify;
            margin: 0;
            padding: 0 40px;
            position: relative;
            z-index: 2;
          }
          
          .quote-mark {
            position: absolute;
            font-size: 80px;
            color: rgba(193, 127, 89, 0.1);
            z-index: 1;
            transition: all 0.3s ease;
            
            &.left {
              top: -30px;
              left: -10px;
            }
            
            &.right {
              bottom: -50px;
              right: -10px;
            }
          }

          &:hover .quote-mark {
            color: rgba(193, 127, 89, 0.15);
            transform: scale(1.05);
          }
        }
      }
      
      .intro-highlights {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;
        margin-top: 50px;
        
        .highlight-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 15px;
          padding: 25px;
          background: transparent;
          border-radius: 20px;
          box-shadow: none;
          transition: all 0.3s ease;
          
          &:hover {
            transform: translateY(-5px);
            
            .highlight-icon {
              transform: rotate(5deg) scale(1.05);
              color: #c17f59;
            }
          }
          
          .highlight-icon {
            width: 70px;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 32px;
            background: transparent;
            border-radius: 50%;
            transition: all 0.3s ease;
          }
          
          .highlight-text {
            font-size: 16px;
            font-weight: 500;
            color: #2c3e50;
            text-align: center;
          }
        }
      }
    }

    .intro-image {
      .image-wrapper {
        position: relative;
        padding: 20px;
        
        .image-frame {
          position: relative;
          border-radius: 30px;
          overflow: hidden;
          box-shadow: 0 15px 30px rgba(0, 0, 0, 0.05);
          
          img {
            width: 100%;
            height: auto;
            display: block;
            transition: all 0.5s ease;
          }
          
          &:hover {
            img {
              transform: scale(1.03);
            }
          }
          
          .frame-decoration {
            display: none;
          }
        }
        
        .image-overlay {
          display: none;
        }
      }
    }
  }
}

.team {
  padding: 120px 0;
  
  .founder {
    margin-bottom: 80px;
    padding: 0 20px;

    @media (max-width: 768px) {
      margin-bottom: 60px;
    }

    .founder-card {
      display: flex;
      gap: 60px;
      max-width: 1000px;
      margin: 0 auto;
      padding: 50px;
      background: #fff;
      border-radius: 30px;
      box-shadow: 0 20px 40px rgba(0,0,0,0.06);
      transition: all 0.4s ease;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0 30px 60px rgba(0,0,0,0.1);

        .founder-image img {
          transform: scale(1.05);
        }
      }

      .founder-image {
        flex: 0 0 300px;
        position: relative;
        
        img {
          width: 100%;
          height: 400px;
          object-fit: cover;
          border-radius: 20px;
          transition: all 0.4s ease;
        }
      }

      .founder-info {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .founder-role {
          color: #c17f59;
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 10px;
          letter-spacing: 1px;
        }

        h3 {
          font-size: 36px;
          color: #2c3e50;
          margin-bottom: 15px;
          font-weight: 600;
        }

        .founder-education {
          color: #666;
          font-size: 16px;
          margin-bottom: 30px;
          line-height: 1.6;
        }

        .founder-quote {
          position: relative;
          padding: 30px 0;
          border-top: 1px solid rgba(0,0,0,0.1);

          .quote-mark {
            position: absolute;
            top: 15px;
            left: 0;
            font-size: 24px;
            color: #c17f59;
            font-style: normal;
          }

          p {
            padding-left: 30px;
            font-style: italic;
            color: #555;
            line-height: 1.8;
          }
        }
      }
    }
  }

  .team-container {
    padding: 0 20px;

    .team-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
      gap: 40px;
      max-width: 1200px;
      margin: 0 auto;

      .team-member {
        background: #fff;
        border-radius: 20px;
        overflow: hidden;
        box-shadow: 0 15px 35px rgba(0,0,0,0.05);
        transition: all 0.4s ease;

        &:hover {
          transform: translateY(-10px);
          box-shadow: 0 25px 45px rgba(0,0,0,0.1);

          .member-image img {
            transform: scale(1.1);
          }
        }

        .member-image {
          position: relative;
          height: 300px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: all 0.4s ease;
          }
        }

        .member-info {
          padding: 25px;
          text-align: center;

          h4 {
            font-size: 20px;
            color: #2c3e50;
            margin-bottom: 8px;
            font-weight: 600;
          }

          p {
            color: #666;
            font-size: 14px;
            line-height: 1.6;
          }
        }
      }
    }
  }
}

.activities {
  background: linear-gradient(to bottom, #fff, #f8f9fa);
  
  .activities-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 @container-padding;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      padding: 0 @container-padding-mobile;
    }

    .activity-card {
      background: #fff;
      border-radius: 15px;
      overflow: hidden;
      box-shadow: 0 10px 20px rgba(0,0,0,0.05);
      transition: all 0.3s ease;
      height: 100%;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 15px 30px rgba(0,0,0,0.1);
      }

      // 学术探索卡片样式
      &.academic {
        .activity-images {
          padding: 15px;

          .image-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
            
            img {
              width: 100%;
              height: 120px;
              object-fit: cover;
              border-radius: 8px;
              cursor: pointer;
              transition: all 0.3s ease;

              &:hover {
                transform: scale(1.05);
                box-shadow: 0 5px 15px rgba(0,0,0,0.1);
              }
            }
          }
        }
      }

      // 专属课程卡片样式
      &.course {
        background: linear-gradient(135deg, #f8f9fa, #fff);
        display: flex;
        align-items: center;

        .course-features {
          list-style: none;
          padding: 0;
          margin: 20px 0 0;

          li {
            padding: 8px 0;
            color: #2c3e50;
            font-size: 15px;
            display: flex;
            align-items: center;

            &:before {
              content: "•";
              color: #c17f59;
              font-size: 20px;
              margin-right: 10px;
            }
          }
        }
      }

      .activity-info {
        padding: 20px;

        h3 {
          font-size: 20px;
          color: #2c3e50;
          margin-bottom: 10px;
          font-weight: 500;
        }

        p {
          color: #666;
          font-size: 15px;
          line-height: 1.6;
          margin-bottom: 0;
        }
      }
    }
  }
}

.partners {
  width: 100% !important;
  max-width: 1400px;
  margin: 0 auto;
  position: relative;
  padding: 80px 0;
  background: #fff;
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 100px;
    z-index: 2;
    pointer-events: none;
  }

  &::before {
    top: 0;
    background: linear-gradient(to bottom, #fff 0%, transparent 100%);
  }

  &::after {
    bottom: 0;
    background: linear-gradient(to top, #fff 0%, transparent 100%);
  }

  .partners-carousel {
    width: 100%;
    overflow: hidden;
    padding: 40px 0;
    position: relative;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      width: 150px;
      z-index: 2;
      pointer-events: none;
    }

    &::before {
      left: 0;
      background: linear-gradient(to right, #fff 0%, transparent 100%);
    }

    &::after {
      right: 0;
      background: linear-gradient(to left, #fff 0%, transparent 100%);
    }

    .carousel-container {
      display: flex;
      transition: transform 0.5s ease;
      padding: 20px 0;
      min-width: max-content;

      img {
        width: 200px;
        height: 250px;
        object-fit: cover;
        margin: 0 40px;
        border-radius: 15px;
        box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
        opacity: 0.85;
        transition: all 0.4s ease;
        
        &:hover {
          opacity: 1;
          transform: translateY(-10px) scale(1.05);
          box-shadow: 0 15px 40px rgba(0, 0, 0, 0.15);
        }
      }
    }
  }

  .background-decoration {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
      radial-gradient(circle at 20% 50%, rgba(193, 127, 89, 0.03) 0%, transparent 50%),
      radial-gradient(circle at 80% 50%, rgba(226, 179, 150, 0.03) 0%, transparent 50%);
    pointer-events: none;
  }
}

.vision-mission {
  background: linear-gradient(to bottom, #fff, #f8f9fa);
  padding: 120px 0;

  .vision-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 @container-padding;

    @media (max-width: 1024px) {
      grid-template-columns: 1fr;
      padding: 0 @container-padding-mobile;
    }

    .vision-card {
      padding: 40px;
      background: #fff;
      border-radius: 20px;
      box-shadow: 0 15px 35px rgba(0,0,0,0.05);
      transition: all 0.4s ease;
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 4px;
        background: linear-gradient(90deg, #c17f59, #e2b396);
      }

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0 25px 45px rgba(0,0,0,0.1);

        .vision-icon {
          transform: scale(1.1) rotate(10deg);
        }
      }

      .vision-icon {
        font-size: 48px;
        margin-bottom: 20px;
        transition: all 0.4s ease;
      }

      h3 {
        font-size: 24px;
        margin-bottom: 20px;
        color: #2c3e50;
        font-weight: 600;
        position: relative;
        padding-bottom: 15px;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 40px;
          height: 2px;
          background: linear-gradient(90deg, #c17f59, #e2b396);
        }
      }

      p {
        color: #666;
        line-height: 1.8;
        font-size: 16px;
        margin: 0;
      }
    }
  }
}

.contact {
  .qr-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 40px;
    justify-items: center;
    padding: 0 @container-padding;

    @media (max-width: 768px) {
      padding: 0 @container-padding-mobile;
    }

    .qr-code {
      text-align: center;
      padding: 30px;
      background: #fff;
      border-radius: 20px;
      box-shadow: 0 15px 35px rgba(0,0,0,0.05);
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0 25px 45px rgba(0,0,0,0.1);
      }

      img {
        width: 180px;
        height: 180px;
        margin-bottom: 20px;
        border-radius: 10px;
        box-shadow: 0 10px 25px rgba(0,0,0,0.1);
      }

      p {
        color: #2c3e50;
        font-size: 16px;
        letter-spacing: 1px;
      }
    }
  }
}

.thank-you-footer {
  position: relative;
  background: linear-gradient(135deg, #1a1a2e 0%, #16213e 100%);
  padding: 60px 0;
  overflow: hidden;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(
      circle at center,
      rgba(100,255,218,0.05) 0%,
      transparent 70%
    );
  }

  .content-wrapper {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
    align-items: center;

    @media (max-width: 968px) {
      grid-template-columns: 1fr;
      gap: 40px;
    }
  }

  .thanks-container {
    .thanks-grid {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;
      justify-content: flex-start;
    }

    .thanks-item {
      opacity: 0;
      animation: popIn 0.6s ease forwards;
    }

    .thanks-bubble {
      background: rgba(255,255,255,0.08);
      backdrop-filter: blur(10px);
      padding: 10px 16px;
      border-radius: 16px;
      font-size: 14px;
      color: rgba(255,255,255,0.9);
      box-shadow: 0 4px 15px rgba(0,0,0,0.1);
      transition: all 0.3s ease;
      border: 1px solid rgba(255,255,255,0.08);
      
      &:hover {
        transform: translateY(-3px);
        background: rgba(255,255,255,0.12);
        border-color: rgba(255,255,255,0.15);
      }
    }
  }

  .footer-container {
    .footer-content {
      color: #fff;
      
      .company-info {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 30px;

        .footer-logo {
          width: 60px;
          height: 60px;
          border-radius: 12px;
          border: 2px solid rgba(255,255,255,0.1);
        }

        .company-details {
          h4 {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 8px;
            color: rgba(255,255,255,0.9);
          }

          p {
            font-size: 14px;
            color: rgba(255,255,255,0.6);
            letter-spacing: 1px;
          }
        }
      }

      .copyright {
        padding-top: 20px;
        border-top: 1px solid rgba(255,255,255,0.1);

        p {
          font-size: 14px;
          color: rgba(255,255,255,0.5);
          margin: 5px 0;
          letter-spacing: 0.5px;

          &:hover {
            color: rgba(255,255,255,0.8);
          }
        }
      }
    }
  }
}

@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(0.8) translateY(10px);
  }
  70% {
    transform: scale(1.05) translateY(-5px);
  }
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

.admin-entry {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  transition: transform 0.3s ease;

  .admin-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    background: linear-gradient(135deg, #3498db, #2c3e50);
    border-radius: 50%;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    position: relative;

    &:hover {
      transform: scale(1.1);
      box-shadow: 0 15px 35px rgba(0, 0, 0, 0.3);
    }

    .admin-icon {
      font-size: 24px;
      color: #fff;
      transition: transform 0.3s ease;

      &:hover {
        transform: rotate(360deg);
      }
    }
  }
}

.hero-decorations {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  pointer-events: none;

  .decoration-circle {
    position: absolute;
    border-radius: 50%;
    border: 1px solid rgba(100,255,218,0.1);
    
    &.circle-1 {
      width: 300px;
      height: 300px;
      top: -150px;
      left: -150px;
      animation: rotate 20s linear infinite;
    }
    
    &.circle-2 {
      width: 500px;
      height: 500px;
      bottom: -250px;
      right: -250px;
      animation: rotate 30s linear infinite reverse;
    }
  }
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.hero {
  .hero-content {
    .hero-text {
      .tagline {
        margin-top: 20px;
        font-size: 1.5rem;
        color: rgba(255, 255, 255, 0.8);
        text-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

// 更新卡片样式为浅色主题
.vision-card,
.activity-card,
.team-member,
.qr-code {
  background: #fff;
  backdrop-filter: none;
  border: none;
  box-shadow: 0 15px 35px rgba(0,0,0,0.05);
  
  &:hover {
    background: #fff;
    border: none;
    transform: translateY(-5px);
    box-shadow: 0 25px 45px rgba(0,0,0,0.1);
  }
}

// 更新合作伙伴部分背景
.partners {
  background: #fff;
  
  &::before,
  &::after {
    background: linear-gradient(to bottom, #fff 0%, transparent 100%);
  }

  .partners-carousel {
    &::before,
    &::after {
      background: linear-gradient(to right, #fff 0%, transparent 100%);
    }
  }
}

</style>

