import Vue from 'vue'
import VueRouter from 'vue-router'
import { getToken } from '@/utils/auth'
// Public
import Public from '@/pages/public/Public'
// Art-Lens
import Home from '@/pages/Home'
import Login from '@/pages/Login'
import Welcome from '@/pages/Welcome'
// 员工管理
import Staffs from '@/pages/team/Staffs'
import Personal from '@/pages/team/Personal'
import Guidance from '@/pages/team/Guidance'
// 活动管理
import Activities from '@/pages/activity/Activities'
import ActivityCreate from '@/pages/activity/Create'
import ActivityDetail from '@/pages/activity/Details'
// 属性管理
import Tags from '@/pages/attribute/Tags'
import Cities from '@/pages/attribute/Cities'
import Features from '@/pages/attribute/Features'
import Audiences from '@/pages/attribute/Audiences'
// 轮播图管理
import Banners from '@/pages/banner/Banners'
import BannerCreate from '@/pages/banner/Create'
import BannerDetail from '@/pages/banner/Details'
// 活动瞬间管理
import Events from '@/pages/event/Events'
import EventCreate from '@/pages/event/Create'
import EventDetail from '@/pages/event/Details'
// 订单管理
import Orders from '@/pages/order/Orders'
import OrderDetail from '@/pages/order/Details'

Vue.use(VueRouter)

const router = new VueRouter({
  routes: [
    {
      path: '/',
      name: 'Public',
      component: Public
    },
    {
      path: '/admin/login',
      name: '登录',
      component: Login
    },
    {
      path: '/admin',
      name: '后台首页',
      component: Home,
      redirect: '/admin/welcome',
      children: [
        {
          path: 'welcome',
          name: '欢迎页',
          component: Welcome
        },
        {
          path: 'users',
          name: '员工列表',
          component: Staffs
        },
        {
          path: 'aboutme',
          name: '个人中心',
          component: Personal
        },
        {
          path: 'guide',
          name: '操作指南',
          component: Guidance
        },
        {
          path: 'activities',
          name: '活动列表',
          component: Activities
        },
        {
          path: 'activity/details/:id',
          name: '活动详情',
          component: ActivityDetail
        },
        {
          path: 'activity/create',
          name: '活动创建',
          component: ActivityCreate
        },
        {
          path: 'cities',
          name: '城市列表',
          component: Cities
        },
        {
          path: 'features',
          name: '活动特色列表',
          component: Features
        },
        {
          path: 'tags',
          name: '活动标签列表',
          component: Tags
        },
        {
          path: 'audiences',
          name: '报名人类型列表',
          component: Audiences
        },
        {
          path: 'banners',
          name: '轮播图列表',
          component: Banners
        },
        {
          path: 'banner/details/:id',
          name: '轮播图详情',
          component: BannerDetail
        },
        {
          path: 'banner/create',
          name: '轮播图创建',
          component: BannerCreate
        },
        {
          path: 'events',
          name: '活动瞬间列表',
          component: Events
        },
        {
          path: 'event/details/:id',
          name: '活动瞬间详情',
          component: EventDetail
        },
        {
          path: 'event/create',
          name: '活动瞬间创建',
          component: EventCreate
        },
        {
          path: 'orders',
          name: '订单列表',
          component: Orders
        },
        {
          path: 'order/details/:id',
          name: '订单详情',
          component: OrderDetail
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  // 公开页面直接放行
  if(to.path === '/' || to.path === '/about') return next()
  
  // 后台页面需要验证登录
  if(to.path === '/admin/login') return next()
  if(!getToken()) return next('/admin/login')
  next()
})

export default router
