/**
 * 验证中国大陆手机号码
 * 支持的号段:
 * 13[0-9], 14[5-9], 15[0-3,5-9], 16[2,5,6,7], 17[0-8], 18[0-9], 19[0-3,5-9]
 */
export const checkMobile = (rule, value, callback) => {
  const refMobile = /^1(3[0-9]|4[5-9]|5[0-3,5-9]|6[2,5,6,7]|7[0-8]|8[0-9]|9[0-3,5-9])\d{8}$/
  
  if (!value) {
    callback(new Error('请输入手机号码'))
    return
  }
  
  if (refMobile.test(value)) {
    callback()
  } else {
    callback(new Error('请输入正确的手机号码'))
  }
}
