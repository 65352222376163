<template>
  <div class="event-create">
    <!-- 顶部操作栏 -->
    <div class="action-bar">
      <div class="left-actions">
        <el-button icon="el-icon-back" size="medium" plain @click="goEvents">返回列表</el-button>
      </div>
      <div class="right-actions">
        <el-button type="primary" icon="el-icon-check" size="medium" @click="submitForm">创建瞬间</el-button>
      </div>
    </div>

    <!-- 主要内容区域 -->
    <div class="main-content">
      <div class="content-grid">
        <!-- 左侧内容列 -->
        <div class="left-column">
          <!-- 标题卡片 -->
          <el-card class="title-card">
            <div class="card-header">
              <span>活动标题</span>
            </div>
            <div class="title-grid">
              <div class="title-cn">
                <div class="title-label">中文标题</div>
                <el-input v-model="eventForm.title_cn" placeholder="请输入中文标题"></el-input>
              </div>
              <div class="title-ug">
                <div class="title-label">ئۇيغۇرچە تېمىسى</div>
                <el-input v-model="eventForm.title_ug" placeholder="ئۇيغۇرچە تېمىسىنى كىرگۈزۈڭ"></el-input>
              </div>
            </div>
          </el-card>

          <!-- 副标题卡片 -->
          <el-card class="subtitle-card">
            <div class="card-header">
              <span>活动副标题</span>
            </div>
            <div class="title-grid">
              <div class="title-cn">
                <div class="title-label">中文副标题</div>
                <el-input v-model="eventForm.sub_title_cn" placeholder="请输入中文副标题"></el-input>
              </div>
              <div class="title-ug">
                <div class="title-label">ئۇيغۇرچە تارماق تېما</div>
                <el-input v-model="eventForm.sub_title_ug" placeholder="ئۇيغۇرچە تارماق تېمىنى كىرگۈزۈڭ"></el-input>
              </div>
            </div>
          </el-card>

          <!-- 基本信息卡片 -->
          <el-card class="info-card">
            <div class="card-header">
              <span>活动信息</span>
            </div>
            <div class="info-grid">
              <div class="info-row">
                <div class="info-item">
                  <div class="info-label">活动时间</div>
                  <el-date-picker
                    v-model="eventForm.event_time"
                    type="month"
                    placeholder="选择活动时间"
                    value-format="yyyy-MM"
                    style="width: 100%">
                  </el-date-picker>
                </div>

                <div class="info-item">
                  <div class="info-label">活动状态</div>
                  <el-select v-model="eventForm.status" placeholder="请选择活动状态" style="width: 100%">
                    <el-option :value="0" label="即将开始"></el-option>
                    <el-option :value="1" label="进行中"></el-option>
                    <el-option :value="2" label="已结束"></el-option>
                  </el-select>
                </div>
              </div>

              <div class="info-item">
                <div class="info-label">跳转链接<span class="info-tip">（选填）</span></div>
                <el-input 
                  v-model="eventForm.hyper_link" 
                  placeholder="请输入跳转链接地址">
                </el-input>
              </div>
            </div>
          </el-card>
        </div>

        <!-- 右侧内容列 -->
        <div class="right-column">
          <!-- 封面图卡片 -->
          <el-card class="images-card">
            <div class="card-header">
              <span>活动封面</span>
            </div>
            <div class="images-wrapper">
              <div class="image-section">
                <div class="cover-grid">
                  <div class="cover-item">
                    <div class="label-cn">中文封面</div>
                    <div class="image-container cover-shape">
                      <template v-if="eventForm.cover_image_cn">
                        <el-image :src="getImageUrl(eventForm.cover_image_cn)">
                        </el-image>
                        <div class="image-actions">
                          <el-button 
                            type="danger" 
                            icon="el-icon-delete" 
                            circle 
                            size="mini" 
                            @click="removeImage('cover_image_cn')">
                          </el-button>
                        </div>
                      </template>
                      <template v-else>
                        <div class="upload-box cover-shape">
                          <el-upload
                            class="image-uploader"
                            :action="getUploadUrl('cover_image_cn')"
                            :headers="getHeaders()"
                            :show-file-list="false"
                            :on-success="(res) => uploaded(res, 'cover_image_cn')"
                            :on-error="uploadError"
                            :before-upload="beforeUpload">
                            <i class="el-icon-plus"></i>
                            <div class="upload-text">上传封面</div>
                          </el-upload>
                        </div>
                      </template>
                    </div>
                  </div>
                  
                  <div class="cover-item">
                    <div class="label-ug">ئۇيغۇرچە مۇقاۋا</div>
                    <div class="image-container cover-shape">
                      <template v-if="eventForm.cover_image_ug">
                        <el-image :src="getImageUrl(eventForm.cover_image_ug)">
                        </el-image>
                        <div class="image-actions">
                          <el-button 
                            type="danger" 
                            icon="el-icon-delete" 
                            circle 
                            size="mini" 
                            @click="removeImage('cover_image_ug')">
                          </el-button>
                        </div>
                      </template>
                      <template v-else>
                        <div class="upload-box cover-shape">
                          <el-upload
                            class="image-uploader"
                            :action="getUploadUrl('cover_image_ug')"
                            :headers="getHeaders()"
                            :show-file-list="false"
                            :on-success="(res) => uploaded(res, 'cover_image_ug')"
                            :on-error="uploadError"
                            :before-upload="beforeUpload">
                            <i class="el-icon-plus"></i>
                            <div class="upload-text">مۇقاۋىنى چىقىرىڭ</div>
                          </el-upload>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-card>

          <!-- 轮播图片卡片 -->
          <el-card class="carousel-images-card">
            <div class="card-header">
              <span>活动轮播图</span>
            </div>
            <div class="images-wrapper">
              <div class="carousel-grid">
                <!-- 中文轮播图列 -->
                <div class="carousel-column">
                  <div class="label-cn">中文轮播图</div>
                  <div class="image-list">
                    <div v-for="(url, index) in eventForm.carousel_images_cn" 
                         :key="index" 
                         class="image-item carousel-shape">
                      <el-image :src="getImageUrl(url)"></el-image>
                      <div class="image-actions">
                        <el-button 
                          type="danger" 
                          icon="el-icon-delete" 
                          circle 
                          size="mini"
                          @click="removeImage('carousel_images_cn', index)">
                        </el-button>
                      </div>
                    </div>
                    <div class="upload-box carousel-shape">
                      <el-upload
                        class="image-uploader"
                        :action="getUploadUrl('carousel_images_cn')"
                        :headers="getHeaders()"
                        :show-file-list="false"
                        :on-success="(res) => uploaded(res, 'carousel_images_cn')"
                        :on-error="uploadError"
                        :before-upload="beforeUpload">
                        <i class="el-icon-plus"></i>
                        <div class="upload-text">上传轮播图</div>
                      </el-upload>
                    </div>
                  </div>
                </div>
                
                <!-- 维文轮播图列 -->
                <div class="carousel-column">
                  <div class="label-ug">ئۇيغۇرچە ئايلانما تەگلىكلەر</div>
                  <div class="image-list">
                    <div v-for="(url, index) in eventForm.carousel_images_ug" 
                         :key="index" 
                         class="image-item carousel-shape">
                      <el-image :src="getImageUrl(url)"></el-image>
                      <div class="image-actions">
                        <el-button 
                          type="danger" 
                          icon="el-icon-delete" 
                          circle 
                          size="mini"
                          @click="removeImage('carousel_images_ug', index)">
                        </el-button>
                      </div>
                    </div>
                    <div class="upload-box carousel-shape">
                      <el-upload
                        class="image-uploader"
                        :action="getUploadUrl('carousel_images_ug')"
                        :headers="getHeaders()"
                        :show-file-list="false"
                        :on-success="(res) => uploaded(res, 'carousel_images_ug')"
                        :on-error="uploadError"
                        :before-upload="beforeUpload">
                        <i class="el-icon-plus"></i>
                        <div class="upload-text">تەگلىكلەرنى چىقىرىڭ</div>
                      </el-upload>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-card>

          <!-- moments 图片卡片 -->
          <el-card class="moments-images-card">
            <div class="card-header">
              <span>活动瞬间图片</span>
            </div>
            <div class="images-wrapper">
              <div class="image-list">
                <div v-for="(url, index) in eventForm.moments" 
                     :key="index" 
                     class="image-item moments-shape">
                  <el-image :src="getImageUrl(url)"></el-image>
                  <div class="image-actions">
                    <el-button 
                      type="danger" 
                      icon="el-icon-delete" 
                      circle 
                      size="mini"
                      @click="removeImage('moments', index)">
                    </el-button>
                  </div>
                </div>
                <div class="upload-box moments-shape">
                  <el-upload
                    class="image-uploader"
                    :action="getUploadUrl('moments')"
                    :headers="getHeaders()"
                    :show-file-list="false"
                    :on-success="(res) => uploaded(res, 'moments')"
                    :on-error="uploadError"
                    :before-upload="beforeUpload">
                    <i class="el-icon-plus"></i>
                    <div class="upload-text">上传瞬间图片</div>
                  </el-upload>
                </div>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { BASE_URL } from '@/utils/urls'

export default {
  name: 'EventCreate',
  data() {
    return {
      serverURL: BASE_URL,
      eventForm: {
        title_cn: '',
        title_ug: '',
        sub_title_cn: '',
        sub_title_ug: '',
        event_time: '',
        hyper_link: '',
        status: null,
        cover_image_cn: '',
        cover_image_ug: '',
        carousel_images_cn: [],
        carousel_images_ug: [],
        moments: []
      }
    }
  },
  methods: {
    goEvents() {
      this.$router.push('/admin/events')
    },

    getUploadUrl(type) {
      return `${this.serverURL}/api/v1/images/image/event/${type}`
    },

    getHeaders() {
      return {
        'Authorization': `Bearer ${getToken()}`
      }
    },

    getImageUrl(path) {
      return `${this.serverURL}${path}`
    },

    beforeUpload(file) {
      const isImage = /^image\/(jpeg|png|gif|jpg)$/.test(file.type)
      const isLt10M = file.size / 1024 / 1024 < 10

      if (!isImage) {
        this.$message.error('只能上传 JPG/PNG/GIF 格式的图片!')
        return false
      }
      if (!isLt10M) {
        this.$message.error('图片大小不能超过 10MB!')
        return false
      }
      return true
    },

    uploaded(response, type) {
      if (!response.data) {
        this.$message.error('上传失败')
        return
      }

      const imageUrl = response.data
      
      if (type === 'moments' || type.includes('carousel_images')) {
        this.eventForm[type].push(imageUrl)
      } else {
        this.eventForm[type] = imageUrl
      }
    },

    uploadError(err) {
      this.$message.error('图片上传失败')
    },

    removeImage(type, index) {
      if (type === 'moments' || type.includes('carousel_images')) {
        this.eventForm[type].splice(index, 1)
      } else {
        this.eventForm[type] = ''
      }
    },

    validateForm() {
      if (!this.eventForm.title_cn || !this.eventForm.title_ug) {
        this.$message.error('请填写标题')
        return false
      }

      if (!this.eventForm.cover_image_cn || !this.eventForm.cover_image_ug) {
        this.$message.error('请上传封面图片')
        return false
      }

      if (this.eventForm.carousel_images_cn.length < 3 || this.eventForm.carousel_images_ug.length < 3) {
        this.$message.error('请上传至少3张轮播图')
        return false
      }

      if (!this.eventForm.event_time) {
        this.$message.error('请选择活动时间')
        return false
      }

      if (this.eventForm.status === null) {
        this.$message.error('请选择活动状态')
        return false
      }

      return true
    },

    async submitForm() {
      if (!this.validateForm()) return

      try {
        const { data: res } = await this.$http.post('/events/events', this.eventForm)
        
        if (res.event_id) {
          this.$message.success('活动瞬间创建成功')
          this.$router.push('/admin/events')
        } else {
          this.$message.error('活动瞬间创建失败')
        }
      } catch (err) {
        this.$message.error(err.response?.data?.detail || '活动瞬间创建失败')
      }
    }
  }
}
</script>

<style lang="less" scoped>
// 复用 banner/Create.vue 的样式
.event-create {
  min-height: 100vh;

  .content-grid {
    display: grid;
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    gap: 20px;
    margin: 0 auto;

    @media screen and (max-width: 1200px) {
      grid-template-columns: 1fr;
      
      .right-column {
        order: 2;
      }
      
      .left-column {
        order: 1;
        transform: translateY(20px);
      }
    }
  }
}

.el-card {
  &.title-card,
  &.subtitle-card,
  &.info-card,
  &.images-card,
  &.carousel-images-card,
  &.moments-images-card {
    margin-bottom: 20px;
    border-radius: 8px;

    .card-header {
      position: relative;
      padding: 8px 20px;
      border-radius: 8px;
      background: #fafafa;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 14px;
        font-weight: 500;
        color: #1f2937;
        padding-left: 10px;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 2px;
          height: 12px;
          background: #1890ff;
          border-radius: 1px;
        }
      }
    }
  }
}

// 顶部操作栏样式
.action-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
  margin-bottom: 20px;
  border-radius: 8px;
}

// 标题和副标题卡片样式
.title-card,
.subtitle-card {
  .title-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    padding: 20px;

    .title-cn, .title-ug {
      .title-label {
        font-size: 13px;
        color: #666;
        margin-bottom: 12px;
      }
    }

    .title-ug {
      direction: rtl;

      .title-label {
        text-align: right;
      }

      .el-input__inner {
        direction: rtl;
        text-align: right;
      }
    }
  }
}

// 基本信息卡片样式
.info-card {
  .info-grid {
    padding: 20px;

    .info-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
      margin-bottom: 20px;
    }

    .info-item {
      background: #fafafa;
      padding: 12px 16px;
      border-radius: 8px;
      border: 1px solid #f0f0f0;

      .info-label {
        font-size: 13px;
        color: #666;
        margin-bottom: 8px;
      }

      .info-tip {
        font-size: 12px;
        color: #999;
      }

      .el-select {
        width: 100%;
      }
    }
  }
}

// 图片相关样式
.images-card,
.carousel-images-card,
.moments-images-card {
  .images-wrapper {
    padding: 20px;
  }

  .image-section {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .cover-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }

  .label-cn {
    font-size: 13px;
    color: #666;
    margin-bottom: 12px;
    direction: ltr;
  }

  .label-ug {
    font-size: 13px;
    color: #666;
    margin-bottom: 12px;
    direction: rtl;
  }

  .image-container {
    position: relative;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;

    .image-actions {
      position: absolute;
      top: 8px;
      right: 8px;
      opacity: 0;
      transition: opacity 0.3s;
      z-index: 1;
    }

    &:hover .image-actions {
      opacity: 1;
    }
  }

  .image-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
    gap: 8px;

    .image-item {
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      background-color: #f8f8f8;

      .image-actions {
        position: absolute;
        top: 8px;
        right: 8px;
        opacity: 0;
        transition: opacity 0.3s;
        z-index: 1;
      }

      &:hover .image-actions {
        opacity: 1;
      }
    }
  }
}

.cover-shape {
  aspect-ratio: 16/9;
}

.carousel-shape {
  aspect-ratio: 16/10;
}

.upload-box {
  border-radius: 8px;
  overflow: hidden;
  background-color: #fafafa;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f5f5f5;
  }

  .image-uploader {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .el-icon-plus {
    font-size: 20px;
    color: #8c939d;
    margin-bottom: 8px;
  }

  .upload-text {
    font-size: 12px;
    color: #666;
  }
}

.el-button {
  padding: 10px 20px;
  transition: all 0.2s ease;

  &--text {
    padding: 4px 8px;
    font-size: 13px;

    &:hover {
      background: rgba(24, 144, 255, 0.06);
      color: #1890ff;
    }
  }
}

.el-input :deep(.el-input__inner) {
  font-family: inherit;
}

.carousel-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  .carousel-column {
    .label-cn, .label-ug {
      font-size: 13px;
      color: #666;
      margin-bottom: 12px;
    }

    .label-ug {
      text-align: right;
      direction: rtl;
    }

    .image-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
      gap: 8px;
    }
  }
}

.moments-shape {
  aspect-ratio: 1/1;
}
</style>
