import axios from 'axios'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'
import { API_BASE_URL } from '@/utils/urls'

NProgress.configure({ showSpinner: false });

const request = axios.create({
    baseURL: API_BASE_URL,
    timeout: 5000
})

request.defaults.headers['Access-Control-Allow-Origin'] = '*';
request.defaults.headers['Content-Type'] = 'application/json;charset=utf-8';

// request 拦截器
request.interceptors.request.use(
    config => {
        NProgress.start()
        if (getToken()) config.headers['Authorization'] = `Bearer ${getToken()}`
        return config
    },
    error => {
        NProgress.done()
        return Promise.reject(error)
    }
);

// response 拦截器
request.interceptors.response.use(
    response => {
        NProgress.done()
        let res = response.data;
        if (response.config.responseType === 'blob') {
            return res
        }
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        return res;
    },
    error => {
        NProgress.done()
        return Promise.reject(error)
    }
);

export default request