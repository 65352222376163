<template>
  <div class="activity-details">
    <!-- 顶部操作栏 -->
    <div class="action-bar">
      <div class="left-actions">
        <el-button icon="el-icon-back" size="medium" plain @click="goBack">返回列表</el-button>
      </div>
      <div class="date-info" :class="{ 'deleted': activityData.is_deleted === 1 }">
        <div v-if="creatorInfo.username">创建时间：{{ creatorInfo.username }} {{ formatDateTime(activityData.created_at) }}</div>
        <div v-if="updaterInfo.username">{{ activityData.is_deleted === 1 ? '删除时间：' : '上次更新：' }}{{ updaterInfo.username }} {{ formatDateTime(activityData.updated_at) }}</div>
      </div>
      <div class="right-actions" v-if="activityData.is_deleted === 0">
        <el-button type="danger" icon="el-icon-delete" size="medium" plain @click="handleDelete">删除活动</el-button>
      </div>
      <div class="right-actions" v-if="activityData.is_deleted === 1">
        <el-button type="success" icon="el-icon-refresh" size="medium" plain @click="handleRestore">恢复活动</el-button>
      </div>
    </div>

    <!-- 主要内容区域 -->
    <div class="main-content">
      <div class="content-grid">
        <!-- 左侧内容列 - 占60% -->
        <div class="left-column">
          <!-- 活动标题卡片 -->
          <el-card class="title-card">
            <div class="card-header">
              <span>活动标题</span>
              <div class="header-actions">
                <template v-if="!editingTitle">
                  <el-button type="text" icon="el-icon-edit" @click="startEditTitle">编辑</el-button>
                </template>
                <template v-else>
                  <el-button type="text" icon="el-icon-check" @click="saveTitle">保存</el-button>
                  <el-button type="text" icon="el-icon-close" @click="cancelEditTitle">取消</el-button>
                </template>
              </div>
            </div>
            <div class="title-grid">
              <div class="title-cn">
                <div class="title-label">中文标题</div>
                <template v-if="!editingTitle">
                  <div class="title-content"><span class="title-text">{{ activityData.title_cn }}</span></div>
                </template>
                <template v-else>
                  <el-input v-model="titleEdit.title_cn" placeholder="请输入中文标题" class="title-input"></el-input>
                </template>
              </div>
              <div class="title-ug">
                <div class="title-label">ئۇيغۇرچە تېمىسى</div>
                <template v-if="!editingTitle">
                  <div class="title-content"><span class="title-text">{{ activityData.title_ug }}</span></div>
                </template>
                <template v-else>
                  <el-input v-model="titleEdit.title_ug" placeholder="ئۇيغۇرچە پائالىيەت تېمىسىنى كىرگۈزۈڭ" class="title-input"></el-input>
                </template>
              </div>
            </div>
          </el-card>

          <!-- 基本信息卡片 -->
          <el-card class="info-card">
            <div class="card-header">
              <span>基本信息</span>
              <div class="header-actions">
                <template v-if="!editingInfo">
                  <el-button type="text" icon="el-icon-edit" @click="startEditInfo">编辑</el-button>
                </template>
                <template v-else>
                  <el-button type="text" icon="el-icon-check" @click="saveInfo">保存</el-button>
                  <el-button type="text" icon="el-icon-close" @click="cancelEditInfo">取消</el-button>
                </template>
              </div>
            </div>
            <div class="info-grid">
              <!-- 非编辑模式 -->
              <template v-if="!editingInfo">
                <!-- 左侧信息列 -->
                <div class="info-column">
                  <div class="info-item">
                    <div class="info-label">活动类型</div>
                    <div class="info-content">
                      <el-tag size="medium" type="info">{{ getActivityType(activityData.type) }}</el-tag>
                    </div>
                  </div>

                  <div class="info-item">
                    <div class="info-label">活动时间</div>
                    <div class="info-content">{{ activityData.start_date }} 至 {{ activityData.end_date }}</div>
                  </div>

                  <div class="info-item">
                    <div class="info-label">报名状态</div>
                    <div class="info-content">
                      <div class="switch-wrapper">
                        <el-switch
                          v-model="activityData.signup_status"
                          :active-value="1"
                          :inactive-value="0"
                          @change="toggleSignupStatus">
                        </el-switch>
                        <span class="switch-text">{{ activityData.signup_status === 1 ? '可报名' : '不可报名' }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- 右侧信息列 -->
                <div class="info-column">
                  <div class="info-item">
                    <div class="info-label">活动状态</div>
                    <div class="info-content">
                      <el-tag 
                        :type="getStatusType(activityData.status)"
                        size="medium">
                        {{ getStatusText(activityData.status) }}
                      </el-tag>
                    </div>
                  </div>

                  <div class="info-item">
                    <div class="info-label">报名截止</div>
                    <div class="info-content">{{ formatDateTime(activityData.signup_deadline) }}</div>
                  </div>

                  <div class="info-item">
                    <div class="info-label">合同状态</div>
                    <div class="info-content">
                      <div class="switch-wrapper">
                        <el-switch
                          v-model="activityData.contract_status"
                          :active-value="1"
                          :inactive-value="0"
                          @change="toggleContractStatus">
                        </el-switch>
                        <span class="switch-text">{{ activityData.contract_status === 1 ? '可签' : '不可签' }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>

              <!-- 编辑模式 -->
              <template v-else>
                <div class="info-column">
                  <div class="info-item">
                    <div class="info-label">活动类型</div>
                    <div class="info-content">
                      <el-select v-model="infoEdit.type" placeholder="请选择活动类型">
                        <el-option
                          v-for="(name, id) in activityTypes"
                          :key="id"
                          :label="name"
                          :value="Number(id)">
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="info-item">
                    <div class="info-label">活动时间</div>
                    <div class="info-content">
                      <el-date-picker
                        v-model="infoEdit.dateRange"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        value-format="yyyy-MM-dd">
                      </el-date-picker>
                    </div>
                  </div>

                  <div class="info-item">
                    <div class="info-label">报名状态</div>
                    <div class="info-content">
                      <div class="switch-wrapper">
                        <el-switch
                          v-model="infoEdit.signup_status"
                          :active-value="1"
                          :inactive-value="0">
                        </el-switch>
                        <span class="switch-text">{{ infoEdit.signup_status === 1 ? '可报名' : '不可报名' }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="info-column">
                  <div class="info-item">
                    <div class="info-label">活动状态</div>
                    <div class="info-content">
                      <el-select v-model="infoEdit.status" placeholder="请选择活动状态">
                        <el-option
                          v-for="(status, id) in statusMap"
                          :key="id"
                          :label="status.text"
                          :value="Number(id)">
                        </el-option>
                      </el-select>
                    </div>
                  </div>

                  <div class="info-item">
                    <div class="info-label">报名截止</div>
                    <div class="info-content">
                      <el-date-picker
                        v-model="infoEdit.signup_deadline"
                        type="datetime"
                        placeholder="选择报名截止时间"
                        value-format="yyyy-MM-dd">
                      </el-date-picker>
                    </div>
                  </div>

                  <div class="info-item">
                    <div class="info-label">合同状态</div>
                    <div class="info-content">
                      <div class="switch-wrapper">
                        <el-switch
                          v-model="infoEdit.contract_status"
                          :active-value="1"
                          :inactive-value="0">
                        </el-switch>
                        <span class="switch-text">{{ infoEdit.contract_status === 1 ? '可签' : '不可签' }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </el-card>

          <!-- 活动描述卡片 -->
          <el-card class="desc-card">
            <div class="card-header">
              <span>活动描述</span>
              <div class="header-actions">
                <template v-if="!editingDesc">
                  <el-button type="text" icon="el-icon-edit" @click="startEditDesc">编辑</el-button>
                </template>
                <template v-else>
                  <el-button type="text" icon="el-icon-check" @click="saveDesc">保存</el-button>
                  <el-button type="text" icon="el-icon-close" @click="cancelEditDesc">取消</el-button>
                </template>
              </div>
            </div>
            <div class="desc-grid">
              <div class="desc-cn">
                <div class="desc-label">中文描述</div>
                <template v-if="!editingDesc">
                  <div class="desc-content">
                    <div v-html="activityData.description_cn" class="description-content"></div>
                  </div>
                </template>
                <template v-else>
                  <el-input
                    type="textarea"
                    v-model="descEdit.description_cn"
                    :rows="6"
                    :maxlength="2000"
                    show-word-limit
                    placeholder="请输入中文描述"
                    resize="none"
                    class="desc-input">
                  </el-input>
                </template>
              </div>
              <div class="desc-ug">
                <div class="desc-label">ئۇيغۇرچە تونۇشتۇرۇلىشى</div>
                <template v-if="!editingDesc">
                  <div class="desc-content">
                    <div v-html="activityData.description_ug" class="description-content"></div>
                  </div>
                </template>
                <template v-else>
                  <el-input
                    type="textarea" 
                    v-model="descEdit.description_ug"
                    :rows="6"
                    :maxlength="8000"
                    show-word-limit
                    placeholder="ئۇيغۇرچە پائالىيەت تونۇشتۇرۇلىشىنى كىرگۈزۈڭ"
                    resize="none"
                    class="desc-input">
                  </el-input>
                </template>
              </div>
            </div>
          </el-card>

          <!-- 报名人群与价格卡片 -->
          <el-card class="audience-card">
            <div class="card-header">
              <span>报名人群与价格</span>
              <div class="header-actions">
                <template v-if="!editingAudience">
                  <el-button type="text" icon="el-icon-edit" @click="startEditAudience">编辑</el-button>
                </template>
                <template v-else>
                  <el-button type="text" icon="el-icon-check" @click="saveAudience">保存</el-button>
                  <el-button type="text" icon="el-icon-close" @click="cancelEditAudience">取消</el-button>
                </template>
              </div>
            </div>
            <div class="audience-grid">
              <div class="audience-header">
                <!-- 最多参与人数部分 -->
                <div class="audience-section max-participants-section">
                  <div class="audience-label">最多参与人数</div>
                  <template v-if="!editingAudience">
                    <div class="max-participants">
                      <span class="participant-value">{{ activityData.max_participants }}</span>
                      <span class="participant-unit">人</span>
                    </div>
                  </template>
                  <template v-else>
                    <el-input-number
                      v-model="audienceEdit.max_participants"
                      :min="1"
                      :max="500"
                      class="participants-input">
                    </el-input-number>
                  </template>
                </div>

                <!-- 价格部分 -->
                <div class="audience-section price-section">
                  <div class="audience-label">活动价格</div>
                  <template v-if="!editingAudience">
                    <div class="price-display">
                      <span class="currency">¥</span>
                      <span class="price-value">{{ activityData.price }}</span>
                    </div>
                  </template>
                  <template v-else>
                    <el-input-number
                      v-model="audienceEdit.price"
                      :min="0"
                      :controls="false"
                      class="price-input"
                      placeholder="请输入价格">
                    </el-input-number>
                  </template>
                </div>
              </div>

              <div class="audience-body">
                <!-- 活动城市部分 -->
                <div class="audience-section city-section">
                  <div class="audience-label">活动城市</div>
                  <template v-if="!editingAudience">
                    <div class="city-list">
                      <el-tag
                        v-for="city in activityData.cities"
                        :key="city.city_id"
                        type="info"
                        effect="light"
                        size="medium">
                        {{ city.name_cn }} / {{ city.name_ug }}
                      </el-tag>
                    </div>
                  </template>
                  <template v-else>
                    <el-select
                      v-model="audienceEdit.cities"
                      multiple
                      filterable
                      placeholder="请选择活动城市"
                      class="city-select">
                      <el-option
                        v-for="city in cityOptions"
                        :key="city.city_id"
                        :label="`${city.name_cn} / ${city.name_ug}`"
                        :value="city.city_id">
                      </el-option>
                    </el-select>
                  </template>
                </div>

                <!-- 报名人群部分 -->
                <div class="audience-section audience-type-section">
                  <div class="audience-label">报名人群</div>
                  <template v-if="!editingAudience">
                    <div class="audience-info">
                      <el-tag
                        type="info"
                        effect="light"
                        size="medium">
                        {{ activityData.audience?.name_cn }} / {{ activityData.audience?.name_ug }}
                      </el-tag>
                    </div>
                  </template>
                  <template v-else>
                    <el-select
                      v-model="audienceEdit.audience_id"
                      placeholder="请选择报名人群"
                      class="audience-select">
                      <el-option
                        v-for="audience in audienceOptions"
                        :key="audience.audience_id"
                        :label="`${audience.name_cn} / ${audience.name_ug}`"
                        :value="audience.audience_id">
                      </el-option>
                    </el-select>
                  </template>
                </div>
              </div>
            </div>
          </el-card>
        </div>

        <!-- 右侧内容列 -->
        <div class="right-column">
          <!-- 图片展示卡片 -->
          <el-card class="images-card">
            <div class="card-header">
              <span>活动图片</span>
              <div class="header-actions">
                <template v-if="!editingImages">
                  <el-button type="text" icon="el-icon-edit" @click="startEditImages">编辑</el-button>
                </template>
                <template v-else>
                  <el-button type="text" icon="el-icon-check" @click="cancelEditImages">完成</el-button>
                </template>
              </div>
            </div>
            
            <div class="images-wrapper">
              <!-- 封面图 -->
              <div class="image-section">
                <div class="cover-grid">
                  <div class="cover-item">
                    <div class="label-cn">中文封面图</div>
                    <div class="image-container cover-shape">
                      <template v-if="activityData.cover_image_cn">
                        <el-image
                          :src="`${serverURL}${activityData.cover_image_cn}`"
                          :preview-src-list="[`${serverURL}${activityData.cover_image_cn}`]">
                        </el-image>
                        <div class="image-actions" v-if="editingImages">
                          <el-button 
                            type="danger" 
                            icon="el-icon-delete" 
                            circle 
                            size="mini" 
                            @click="removeImage('cover', 'cn')">
                          </el-button>
                        </div>
                      </template>
                      <template v-else>
                        <div class="upload-box cover-shape">
                          <el-upload
                            class="image-uploader"
                            :action="getUploadUrl('cover_cn')"
                            :headers="getHeaders()"
                            :show-file-list="false"
                            :on-success="(res) => uploaded(res, 'cover_cn')"
                            :before-upload="beforeUpload">
                            <i class="el-icon-plus"></i>
                            <div class="upload-text">上传封面图</div>
                          </el-upload>
                        </div>
                      </template>
                    </div>
                  </div>
                  
                  <div class="cover-item">
                    <div class="label-ug">ئۇيغۇرچە مۇقاۋا</div>
                    <div class="image-container cover-shape"
                         :class="{ 'is-editing': editingImages }"
                         @click="editingImages && handleCoverImageClick('UG')">
                      <template v-if="activityData.cover_image_ug">
                        <el-image
                          :src="`${serverURL}${activityData.cover_image_ug}`"
                          :preview-src-list="[`${serverURL}${activityData.cover_image_ug}`]">
                        </el-image>
                        <div class="image-actions" v-if="editingImages">
                          <el-button 
                            type="danger" 
                            icon="el-icon-delete" 
                            circle 
                            size="mini" 
                            @click="removeImage('cover', 'ug')">
                          </el-button>
                        </div>
                      </template>
                      <template v-else>
                        <div class="upload-box cover-shape">
                          <el-upload
                            class="image-uploader"
                            :action="getUploadUrl('cover_ug')"
                            :headers="getHeaders()"
                            :show-file-list="false"
                            :on-success="(res) => uploaded(res, 'cover_ug')"
                            :before-upload="beforeUpload">
                            <i class="el-icon-plus"></i>
                            <div class="upload-text">上传封面图</div>
                          </el-upload>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 轮播图 -->
              <div class="image-section">
                <div class="carousel-grid">
                  <div class="carousel-column">
                    <div class="label-cn">中文轮播图</div>
                    <div class="image-list" :style="!editingImages ? 'min-height: 80px;' : ''">
                      <template v-if="editingImages">
                        <!-- 编辑模式 -->
                        <div v-for="(url, index) in imagesEdit.carousel_images_cn" :key="index" class="image-item carousel-shape">
                          <el-image :src="`${serverURL}${url}`"></el-image>
                          <div class="image-actions"><el-button type="danger" icon="el-icon-delete" circle size="mini" @click="removeImage('carousel', 'cn', index)"></el-button></div>
                        </div>
                        <div class="upload-box carousel-shape">
                          <el-upload class="image-uploader" :action="getUploadUrl('carousel_cn')" :headers="getHeaders()" :show-file-list="false" :on-success="(res) => uploaded(res, 'carousel_cn')" :before-upload="beforeUpload">
                            <i class="el-icon-plus"></i>
                            <div class="upload-text">上传轮播图</div>
                          </el-upload>
                        </div>
                      </template>
                      <!-- 非编辑模式 -->
                      <template v-else>
                        <div v-for="(url, index) in activityData.carousel_images_cn"
                             :key="index"
                             class="image-item carousel-shape">
                          <el-image 
                            :src="`${serverURL}${url}`"
                            :preview-src-list="activityData.carousel_images_cn.map(img => `${serverURL}${img}`)">
                          </el-image>
                        </div>
                      </template>
                    </div>
                  </div>
                  
                  <div class="carousel-column">
                    <div class="label-ug">ئۇيغۇرچە ئايلانما تەگلىكلەر</div>
                    <div class="image-list" :style="!editingImages ? 'min-height: 80px;' : ''">
                      <template v-if="editingImages">
                        <!-- 编辑模式 -->
                        <div v-for="(url, index) in imagesEdit.carousel_images_ug"
                              :key="index"
                              class="image-item carousel-shape">
                          <el-image :src="`${serverURL}${url}`"></el-image>
                          <div class="image-actions">
                            <el-button 
                              type="danger" 
                              icon="el-icon-delete" 
                              circle 
                              size="mini"
                              @click="removeImage('carousel', 'ug', index)">
                            </el-button>
                          </div>
                        </div>
                        <!-- 添加上传按钮 -->
                        <div class="upload-box carousel-shape">
                          <el-upload
                            class="image-uploader"
                            :action="getUploadUrl('carousel_ug')"
                            :headers="getHeaders()"
                            :show-file-list="false"
                            :on-success="(res) => uploaded(res, 'carousel_ug')"
                            :before-upload="beforeUpload">
                            <i class="el-icon-plus"></i>
                            <div class="upload-text">上传轮播图</div>
                          </el-upload>
                        </div>
                      </template>
                      <!-- 非编辑模式 -->
                      <template v-else>
                        <div v-for="(url, index) in activityData.carousel_images_ug"
                             :key="index"
                             class="image-item carousel-shape">
                          <el-image 
                            :src="`${serverURL}${url}`"
                            :preview-src-list="activityData.carousel_images_ug.map(img => `${serverURL}${img}`)">
                          </el-image>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>

              <!-- 海报图 -->
              <div class="image-section">
                <div class="poster-grid">
                  <div class="poster-column">
                    <div class="label-cn">中文长图海报</div>
                    <div class="image-list" :style="!editingImages ? 'min-height: 80px;' : ''">
                      <template v-if="editingImages">
                        <!-- 编辑模式 -->
                        <div v-for="(url, index) in imagesEdit.poster_images_cn"
                              :key="index"
                              class="image-item poster-shape">
                          <el-image :src="`${serverURL}${url}`"></el-image>
                          <div class="image-actions">
                            <el-button 
                              type="danger" 
                              icon="el-icon-delete" 
                              circle 
                              size="mini"
                              @click="removeImage('poster', 'cn', index)">
                            </el-button>
                          </div>
                        </div>
                        <!-- 添加上传按钮 -->
                        <div class="upload-box poster-shape">
                          <el-upload
                            class="image-uploader"
                            :action="getUploadUrl('poster_cn')"
                            :headers="getHeaders()"
                            :show-file-list="false"
                            :on-success="(res) => uploaded(res, 'poster_cn')"
                            :before-upload="beforeUpload">
                            <i class="el-icon-plus"></i>
                            <div class="upload-text">上传海报</div>
                          </el-upload>
                        </div>
                      </template>
                      <!-- 非编辑模式 -->
                      <template v-else>
                        <div v-for="(url, index) in activityData.poster_images_cn"
                             :key="index"
                             class="image-item poster-shape">
                          <el-image 
                            :src="`${serverURL}${url}`"
                            :preview-src-list="activityData.poster_images_cn.map(img => `${serverURL}${img}`)">
                          </el-image>
                        </div>
                      </template>
                    </div>
                  </div>
                  
                  <div class="poster-column">
                    <div class="label-ug">ئۇيغۇرچە ئۇزۇن ئېلانلار</div>
                    <div class="image-list" :style="!editingImages ? 'min-height: 80px;' : ''">
                      <template v-if="editingImages">
                        <!-- 编辑模式 -->
                        <div v-for="(url, index) in imagesEdit.poster_images_ug"
                              :key="index"
                              class="image-item poster-shape">
                          <el-image :src="`${serverURL}${url}`"></el-image>
                          <div class="image-actions">
                            <el-button 
                              type="danger" 
                              icon="el-icon-delete" 
                              circle 
                              size="mini"
                              @click="removeImage('poster', 'ug', index)">
                            </el-button>
                          </div>
                        </div>
                        <!-- 添加上传按钮 -->
                        <div class="upload-box poster-shape">
                          <el-upload
                            class="image-uploader"
                            :action="getUploadUrl('poster_ug')"
                            :headers="getHeaders()"
                            :show-file-list="false"
                            :on-success="(res) => uploaded(res, 'poster_ug')"
                            :before-upload="beforeUpload">
                            <i class="el-icon-plus"></i>
                            <div class="upload-text">上传海报</div>
                          </el-upload>
                        </div>
                      </template>
                      <!-- 非编辑模式 -->
                      <template v-else>
                        <div v-for="(url, index) in activityData.poster_images_ug"
                             :key="index"
                             class="image-item poster-shape">
                          <el-image 
                            :src="`${serverURL}${url}`"
                            :preview-src-list="activityData.poster_images_ug.map(img => `${serverURL}${img}`)">
                          </el-image>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-card>

          <!-- 标签与特色卡片 -->
          <el-card class="meta-card">
            <div class="card-header">
              <span>标签与特色</span>
              <div class="header-actions">
                <template v-if="!editingMeta">
                  <el-button type="text" icon="el-icon-edit" @click="startEditMeta">编辑</el-button>
                </template>
                <template v-else>
                  <el-button type="text" icon="el-icon-check" @click="saveMeta">保存</el-button>
                  <el-button type="text" icon="el-icon-close" @click="cancelEditMeta">取消</el-button>
                </template>
              </div>
            </div>
            <div class="meta-grid">
              <!-- 活动标签 -->
              <div class="meta-section">
                <div class="meta-title">活动标签</div>
                <template v-if="!editingMeta">
                  <div class="tag-list">
                    <el-tag 
                      v-if="activityData.tag"
                      size="medium"
                      type="success"
                      effect="plain">
                      {{ activityData.tag.name_cn }} / {{ activityData.tag.name_ug }}
                    </el-tag>
                  </div>
                </template>
                <template v-else>
                  <div class="tag-editor">
                    <div class="tag-selector">
                      <el-select
                        v-model="selectedTags"
                        placeholder="请选择标签"
                        @change="handleTagsChange">
                        <el-option
                          v-for="item in tagOptions"
                          :key="item.tag_id"
                          :label="`${item.name_cn} / ${item.name_ug}`"
                          :value="item.tag_id">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </template>
              </div>

              <!-- 活动特色 -->
              <div class="meta-section">
                <div class="meta-title">活动特色</div>
                <template v-if="!editingMeta">
                  <div class="tag-list">
                    <el-tag 
                      v-for="feature in activityData.features" 
                      :key="feature.feature_id"
                      type="success"
                      size="medium"
                      effect="plain">
                      {{ feature.name_cn }} / {{ feature.name_ug }}
                    </el-tag>
                  </div>
                </template>
                <template v-else>
                  <div class="tag-editor">
                    <div class="tag-selector">
                      <el-select
                        v-model="selectedFeatures"
                        multiple
                        reserve-keyword
                        placeholder="请选择活动特色"
                        @change="handleFeaturesChange">
                        <el-option
                          v-for="item in featureOptions"
                          :key="item.feature_id"
                          :label="`${item.name_cn} / ${item.name_ug}`"
                          :value="item.feature_id">
                        </el-option>
                      </el-select>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { BASE_URL } from '@/utils/urls'

export default {
  name: 'ActivityDetails',
  data() {
    return {
      serverURL: BASE_URL,
      activityTypes: {
        1: '内地研学',
        2: '疆内研学',
        3: '主题研学',
        4: '订制团'
      },
      statusMap: {
        1: { text: '未开始', type: 'info' },
        2: { text: '进行中', type: 'success' },
        3: { text: '已结束', type: 'danger' }
      },
      activityData: {
        title_cn: '',
        title_ug: '',
        type: 1,
        start_date: '',
        end_date: '',
        signup_deadline: '',
        max_participants: 0,
        description_cn: '',
        description_ug: '',
        status: 0,
        features: [],
        tags: [],
        cities: [],
        audiences: [],
        activity_audiences: []
      },
      editingTitle: false,
      editingDesc: false,
      editingMeta: false,
      titleEdit: {},
      descEdit: {},
      metaEdit: {},
      selectedTags: [],
      selectedFeatures: [],
      tagOptions: [],
      featureOptions: [],
      creatorInfo: {},
      updaterInfo: {},
      editingInfo: false,
      infoEdit: {
        type: null,
        status: null,
        dateRange: [],
        signup_deadline: '',
        signup_status: 0,
        contract_status: 0
      },
      editingAudience: false,
      audienceEdit: {
        cities: [],
        audience_id: '',
        price: 0,
        max_participants: 0
      },
      cityOptions: [],
      audienceOptions: [],
      editingImages: false,
      imagesEdit: {
        cover_image_cn: '',
        cover_image_ug: '',
        carousel_images_cn: [],
        carousel_images_ug: [],
        poster_images_cn: [],
        poster_images_ug: []
      }
    }
  },
  created() {
    this.getActivityDetails()
  },
  methods: {
    async getActivityDetails() {
      const activityId = this.$route.params.id
      try {
        const res = await this.$http.get(`activities/${activityId}`)
        this.activityData = res.data
        
        if (this.activityData.created_by) {
          await this.getUserInfo(this.activityData.created_by, 'creator')
        }
        if (this.activityData.updated_by) {
          await this.getUserInfo(this.activityData.updated_by, 'updater')
        }
      } catch (err) {
        this.$message.error(err.response?.data?.detail || '获取活动详情失败')
      }
    },
    async getUserInfo(userId, type) {
      try {
        const res = await this.$http.get(`users/${userId}`)
        if (type === 'creator') {
          this.creatorInfo = res.data
        } else if (type === 'updater') {
          this.updaterInfo = res.data
        }
      } catch (err) {
        console.error(`获取${type === 'creator' ? '创建者' : '更新者'}信息失败:`, err)
      }
    },
    goBack() {
      this.$router.push('/admin/activities')
    },
    handleDelete() {
      this.$confirm('确认删除该活动?', '提示', {
        type: 'warning'
      }).then(async () => {
        await this.$http({ method: 'delete', url: `activities/${this.$route.params.id}` })
        this.$message.success('删除成功')
        this.goBack()
      }).catch(err => {
        this.$message.error(err.response?.data?.detail || '删除失败')
      })
    },
    async handleRestore() {
      const activityId = this.$route.params.id
      try {
        await this.$http.put(`activities/${activityId}`, { is_deleted: 0 })
        this.$message.success('恢复成功')
        this.getActivityDetails()
      } catch (err) {
        this.$message.error(err.response?.data?.detail || '恢复失败')
      }
    },
    getActivityType(type) {
      return this.activityTypes[type] || '未知'
    },
    getStatusType(status) {
      const statusTypeMap = { 1: 'info', 2: 'success', 3: 'danger' }
      return statusTypeMap[status] || ''
    },
    getStatusText(status) {
      return this.statusMap[status]?.text || '未知'
    },
    getAudienceName(audienceId) {
      const audience = this.activityData.audiences.find(a => a.audience_id === audienceId)
      return audience ? `${audience.name_cn} / ${audience.name_ug}` : ''
    },
    formatDateTime(date) {
      if (!date) return '';
      return new Date(date).toLocaleString('zh-CN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      });
    },
    async getTags(query) {
      try {
        const res = await this.$http.get('tags', {
          params: { query }
        })
        this.tagOptions = res.data
      } catch (err) {
        this.$message.error('获取标签列表失败')
      }
    },
    async getFeatures(query) {
      try {
        const res = await this.$http.get('features', {
          params: { query }
        })
        this.featureOptions = res.data
      } catch (err) {
        this.$message.error('获取活动特色列表失败')
      }
    },
    async getCityOptions() {
      try {
        const res = await this.$http.get('cities')
        this.cityOptions = res.data
      } catch (err) {
        this.$message.error('获取城市列表失败')
      }
    },
    async getAudienceOptions() {
      try {
        const res = await this.$http.get('audiences')
        this.audienceOptions = res.data
      } catch (err) {
        this.$message.error('获取报名人群列表失败')
      }
    },
    handleTagsChange(value) {
      const selectedTag = this.tagOptions.find(tag => tag.tag_id === value)
      this.metaEdit.tag = selectedTag || null
    },
    handleTagRemove(tag) {
      const index = this.selectedTags.indexOf(tag.tag_id)
      if (index > -1) {
        this.selectedTags.splice(index, 1)
        this.handleTagsChange(this.selectedTags)
      }
    },
    handleFeaturesChange(values) {
      const selectedFeatures = this.featureOptions.filter(feature => 
        values.includes(feature.feature_id)
      )
      this.metaEdit.features = selectedFeatures
    },
    handleFeatureRemove(feature) {
      const index = this.selectedFeatures.indexOf(feature.feature_id)
      if (index > -1) {
        this.selectedFeatures.splice(index, 1)
        this.handleFeaturesChange(this.selectedFeatures)
      }
    },
    handleCitiesChange(values) {
      const selectedCities = this.cityOptions.filter(city => 
        values.includes(city.city_id)
      )
      this.audienceEdit.cities = selectedCities
    },
    handleCityRemove(city) {
      const index = this.selectedCities.indexOf(city.city_id)
      if (index > -1) {
        this.selectedCities.splice(index, 1)
        this.handleCitiesChange(this.selectedCities)
      }
    },
    startEditTitle() {
      this.editingTitle = true
      this.titleEdit = {
        title_cn: this.activityData.title_cn,
        title_ug: this.activityData.title_ug
      }
    },
    async saveTitle() {
      try {
        if (!this.titleEdit.title_cn || !this.titleEdit.title_ug) {
          this.$message.warning('中文和维文标题都不能为空')
          return
        }

        await this.$http.put(`activities/${this.$route.params.id}`, {
          title_cn: this.titleEdit.title_cn,
          title_ug: this.titleEdit.title_ug
        })
        
        this.$message.success('标题更新成功')
        this.editingTitle = false
        this.getActivityDetails()
      } catch (err) {
        this.$message.error(err.response?.data?.detail || '标题更新失败')
      }
    },
    cancelEditTitle() {
      this.editingTitle = false
      this.titleEdit = {}
    },
    startEditDesc() {
      this.editingDesc = true
      this.descEdit = {
        description_cn: this.activityData.description_cn,
        description_ug: this.activityData.description_ug
      }
    },
    async saveDesc() {
      try {
        if (!this.descEdit.description_cn || !this.descEdit.description_ug) {
          this.$message.warning('中文和维文描述都不能为空')
          return
        }

        await this.$http.put(`activities/${this.$route.params.id}`, {
          description_cn: this.descEdit.description_cn,
          description_ug: this.descEdit.description_ug
        })
        
        this.$message.success('活动描述更新成功')
        this.editingDesc = false
        this.getActivityDetails()
      } catch (err) {
        this.$message.error(err.response?.data?.detail || '活动描述更新失败')
      }
    },
    cancelEditDesc() {
      this.$confirm('确认取消编辑？未保存的修改将丢失', '提示', {
        type: 'warning'
      }).then(() => {
        this.editingDesc = false
        this.descEdit = {}
      }).catch(() => {})
    },
    startEditMeta() {
      this.editingMeta = true
      this.metaEdit = {
        tag: this.activityData.tag,
        features: [...this.activityData.features]
      }
      this.getTags()
      this.getFeatures()
      this.selectedTags = this.metaEdit.tag ? this.metaEdit.tag.tag_id : null
      this.selectedFeatures = this.metaEdit.features.map(feature => feature.feature_id)
      this.tagOptions = this.metaEdit.tag ? [this.metaEdit.tag] : []
      this.featureOptions = [...this.metaEdit.features]
    },
    async saveMeta() {
      try {
        await this.$http.put(`activities/${this.$route.params.id}`, {
          tag_id: this.metaEdit.tag ? this.metaEdit.tag.tag_id : null,
          feature_ids: this.metaEdit.features.map(feature => feature.feature_id)
        })
        this.$message.success('标签与特色更新成功')
        this.editingMeta = false
        this.getActivityDetails()
      } catch (err) {
        this.$message.error(err.response?.data?.detail || '标签与特色更新失败')
      }
    },
    cancelEditMeta() {
      this.editingMeta = false
      this.metaEdit = {}
      this.selectedTags = null
      this.selectedFeatures = []
      this.tagOptions = []
      this.featureOptions = []
    },
    startEditInfo() {
      this.editingInfo = true
      this.infoEdit = {
        type: this.activityData.type,
        status: this.activityData.status,
        dateRange: [this.activityData.start_date, this.activityData.end_date],
        signup_deadline: this.activityData.signup_deadline,
        signup_status: this.activityData.signup_status,
        contract_status: this.activityData.contract_status
      }
    },
    async saveInfo() {
      try {
        if (!this.infoEdit.type || !this.infoEdit.status || 
            !this.infoEdit.dateRange || !this.infoEdit.signup_deadline) {
          this.$message.warning('请填写完整信息')
          return
        }

        const [start_date, end_date] = this.infoEdit.dateRange

        await this.$http.put(`activities/${this.$route.params.id}`, {
          type: this.infoEdit.type,
          status: this.infoEdit.status,
          start_date,
          end_date,
          signup_deadline: this.infoEdit.signup_deadline,
          signup_status: this.infoEdit.signup_status,
          contract_status: this.infoEdit.contract_status
        })

        this.$message.success('基本信息更新成功')
        this.editingInfo = false
        this.getActivityDetails()
      } catch (err) {
        this.$message.error(err.response?.data?.detail || '基本信息更新失败')
      }
    },
    cancelEditInfo() {
      this.editingInfo = false
      this.infoEdit = {}
    },
    async toggleSignupStatus(status) {
      const activityId = this.$route.params.id
      try {
        await this.$http.put(`activities/${activityId}`, { signup_status: status })
      } catch (err) {
        this.$message.error(err.response?.data?.detail || '报名状态更新失败')
        this.activityData.signup_status = status === 1 ? 0 : 1
      }
    },
    async toggleContractStatus(status) {
      const activityId = this.$route.params.id
      try {
        await this.$http.put(`activities/${activityId}`, { contract_status: status })
      } catch (err) {
        this.$message.error(err.response?.data?.detail || '合同状态更新失败')
        this.activityData.contract_status = status === 1 ? 0 : 1
      }
    },
    async startEditAudience() {
      this.editingAudience = true
      // 深拷贝当前数据
      this.audienceEdit = {
        cities: this.activityData.cities.map(city => city.city_id),
        audience_id: this.activityData.audience?.audience_id || '',
        price: this.activityData.price || 0,
        max_participants: this.activityData.max_participants
      }

      try {
        await Promise.all([
          this.getCityOptions(),
          this.getAudienceOptions()
        ])
      } catch (err) {
        this.$message.error('获取选项数据失败')
        this.editingAudience = false
      }
    },
    async saveAudience() {
      try {
        if (this.audienceEdit.cities.length === 0) {
          this.$message.warning('请至少选择一个城市')
          return
        }

        if (!this.audienceEdit.audience_id) {
          this.$message.warning('请选择报名人群')
          return
        }

        if (!this.audienceEdit.max_participants || this.audienceEdit.max_participants <= 0) {
          this.$message.warning('请设置有效的最多参与人数')
          return
        }

        if (this.audienceEdit.price < 0) {
          this.$message.warning('请设置有效的价格')
          return
        }

        await this.$http.put(`activities/${this.$route.params.id}`, {
          city_ids: this.audienceEdit.cities,
          audience_id: this.audienceEdit.audience_id,
          price: this.audienceEdit.price,
          max_participants: this.audienceEdit.max_participants
        })

        this.$message.success('报名人群与价格更新成功')
        this.editingAudience = false
        this.getActivityDetails()
      } catch (err) {
        this.$message.error(err.response?.data?.detail || '报名人群与价格更新失败')
      }
    },
    cancelEditAudience() {
      this.editingAudience = false
      this.audienceEdit = {
        cities: [],
        audience_id: '',
        price: 0,
        max_participants: 0
      }
    },
    async getCityOptions() {
      try {
        const res = await this.$http.get('cities')
        this.cityOptions = res.data
      } catch (err) {
        console.error('获取城市列表失败:', err)
        throw err
      }
    },
    async getAudienceOptions() {
      try {
        const res = await this.$http.get('audiences')
        this.audienceOptions = res.data
      } catch (err) {
        console.error('获取报名人群列表失败:', err)
        throw err
      }
    },

    startEditImages() {
      this.editingImages = true
      this.imagesEdit = {
        cover_image_cn: this.activityData.cover_image_cn,
        cover_image_ug: this.activityData.cover_image_ug,
        carousel_images_cn: [...this.activityData.carousel_images_cn],
        carousel_images_ug: [...this.activityData.carousel_images_ug],
        poster_images_cn: [...this.activityData.poster_images_cn],
        poster_images_ug: [...this.activityData.poster_images_ug]
      }
    },

    cancelEditImages() {
      this.editingImages = false
      this.imagesEdit = {}
    },

    getUploadUrl(imageType) {
      return `${this.serverURL}/api/v1/activities/images/${imageType}?activity_name=${this.activityData.title_cn}`
    },

    getHeaders() {
      const token = getToken()
      return {
        'Authorization': `Bearer ${token}`
      }
    },

    beforeUpload(file) {
      const isImage = /^image\/(jpeg|png|gif|jpg)$/.test(file.type)
      const isLt10M = file.size / 1024 / 1024 < 10

      if (!isImage) {
        this.$message.error('只能上传 JPG/PNG/GIF 格式的图片!')
        return false
      }
      if (!isLt10M) {
        this.$message.error('图片大小不能超过 10MB!')
        return false
      }
      return true
    },

    async uploaded(response, type) {
      if (!response.data) {
        this.$message.error('上传失败，请重试')
        return
      }

      const imageUrl = response.data
      const imageType = type.split('_')[0]
      const lang = type.split('_')[1]

      try {
        switch (imageType) {
          case 'cover':
            this.imagesEdit[`cover_image_${lang}`] = imageUrl
            await this.$http.put(`activities/${this.$route.params.id}`, {
              cover_image_cn: this.imagesEdit.cover_image_cn,
              cover_image_ug: this.imagesEdit.cover_image_ug
            }).then(() => {
              this.$message.success('封面图上传成功')
              this.getActivityDetails()
            }).catch(() => {
              this.$message.error('封面图上传失败')
              this.imagesEdit[`cover_image_${lang}`] = ''
            })
            break
          case 'carousel':
            if (!this.imagesEdit[`carousel_images_${lang}`]) {
              this.imagesEdit[`carousel_images_${lang}`] = []
            }
            this.imagesEdit[`carousel_images_${lang}`].push(imageUrl)
            await this.$http.put(`activities/${this.$route.params.id}`, {
              carousel_images_cn: this.imagesEdit.carousel_images_cn,
              carousel_images_ug: this.imagesEdit.carousel_images_ug
            }).then(() => {
              this.$message.success('轮播图上传成功')
              this.getActivityDetails()
            }).catch(() => {
              this.$message.error('轮播图上传失败')
              this.imagesEdit[`carousel_images_${lang}`].pop()
            })
            break
          case 'poster':
            if (!this.imagesEdit[`poster_images_${lang}`]) {
              this.imagesEdit[`poster_images_${lang}`] = []
            }
            this.imagesEdit[`poster_images_${lang}`].push(imageUrl)
            await this.$http.put(`activities/${this.$route.params.id}`, {
              poster_images_cn: this.imagesEdit.poster_images_cn,
              poster_images_ug: this.imagesEdit.poster_images_ug
            }).then(() => {
              this.$message.success('海报图上传成功')
              this.getActivityDetails()
            }).catch(() => {
              this.$message.error('海报图上传失败')
              this.imagesEdit[`poster_images_${lang}`].pop()
            })
            break
        }
      } catch (err) {
        this.$message.error('处理上传结果失败')
        console.error('Upload success handler error:', err)
      }
    },

    async removeImage(type, lang, index) {
      try {
        const confirmResult = await this.$confirm('确认删除该图片?', '提示', {
          type: 'warning',
          confirmButtonText: '确定',
          cancelButtonText: '取消'
        })

        if (confirmResult === 'confirm') {
          switch (type) {
            case 'cover':
              this.imagesEdit[`cover_image_${lang}`] = ''
              await this.$http.put(`activities/${this.$route.params.id}`, {
                cover_image_cn: this.imagesEdit.cover_image_cn,
                cover_image_ug: this.imagesEdit.cover_image_ug
              }).then(() => {
                this.$message.success('封面图删除成功')
                this.getActivityDetails()
              }).catch(() => {
                this.$message.error('封面图删除失败')
                this.imagesEdit[`cover_image_${lang}`] = ''
              })
              break
            case 'carousel':
              this.imagesEdit[`carousel_images_${lang}`].splice(index, 1)
              await this.$http.put(`activities/${this.$route.params.id}`, {
                carousel_images_cn: this.imagesEdit.carousel_images_cn,
                carousel_images_ug: this.imagesEdit.carousel_images_ug
              }).then(() => {
                this.$message.success('轮播图删除成功')
                this.getActivityDetails()
              }).catch(() => {
                this.$message.error('轮播图删除失败')
                this.imagesEdit[`carousel_images_${lang}`].push(imageUrl)
              })
              break
            case 'poster':
              this.imagesEdit[`poster_images_${lang}`].splice(index, 1)
              await this.$http.put(`activities/${this.$route.params.id}`, {
                poster_images_cn: this.imagesEdit.poster_images_cn,
                poster_images_ug: this.imagesEdit.poster_images_ug
              }).then(() => {
                this.$message.success('海报图删除成功')
                this.getActivityDetails()
              }).catch(() => {
                this.$message.error('海报图删除失败')
                this.imagesEdit[`poster_images_${lang}`].push(imageUrl)
              })
              break
          }
        }
      } catch (err) {
        if (err !== 'cancel') {
          this.$message.error('删除失败')
          console.error('Remove image error:', err)
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.activity-details {
  min-height: 100vh;

  .content-grid {
    display: grid;
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    gap: 20px;
    margin: 0 auto;

    @media screen and (max-width: 1200px) {
      grid-template-columns: 1fr;
      
      .right-column {
        order: 2;
      }
      
      .left-column {
        order: 1;
        transform: translateY(20px);
      }
    }
  }
}

.el-card {
  &.title-card,
  &.desc-card,
  &.info-card,
  &.audience-card,
  &.meta-card,
  &.images-card {
    margin-bottom: 20px;
    border-radius: 8px;

    .card-header {
      position: relative;
      padding: 8px 20px;
      border-radius: 8px;
      background: #fafafa;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 14px;
        font-weight: 500;
        color: #1f2937;
        padding-left: 10px;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 2px;
          height: 12px;
          background: #1890ff;
          border-radius: 1px;
        }
      }

      .header-actions {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translateY(-50%);
        z-index: 1;
      }
    }
  }
}

// 顶部操作栏样式
.action-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
  margin-bottom: 20px;
  border-radius: 8px;

  .date-info {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 48px;
    color: #888;
    font-size: 13px;
  }

  .deleted {
    color: #ff4d4f;
  }
}

// 标题卡片样式
.title-card {
  .title-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    padding: 20px;

    .title-cn, .title-ug {
      .title-label {
        font-size: 13px;
        color: #666;
        margin-bottom: 12px;
      }

      .title-content {
        background: #fafafa;
        padding: 7px 15px;
        border-radius: 4px;
        border: 1px solid #f0f0f0;

        .title-text {
          font-size: 14px;
          color: #262626;
          line-height: 1.6;
        }
      }
    }

    .title-ug {
      direction: rtl;

      .title-label {
        text-align: right;
      }

      .el-input__inner {
        direction: rtl;
        text-align: right;
      }
    }
  }
}

// 描述卡片样式
.desc-card {
  .desc-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    padding: 20px;

    .desc-cn, .desc-ug {
      .desc-label {
        font-size: 13px;
        color: #666;
        margin-bottom: 12px;
      }

      .desc-content {
        background: #fafafa;
        padding: 6px 15px;
        border-radius: 4px;
        border: 1px solid #f0f0f0;
        min-height: 80px;

        .description-content {
          font-size: 14px;
          color: #262626;
        }
      }
    }

    .desc-ug {
      direction: rtl;

      .desc-label {
        text-align: right;
      }

      .el-textarea__inner {
        direction: rtl;
        text-align: right;
      }
    }
  }
}

// 基本信息卡片样式
.info-card {
  .info-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    padding: 20px;

    .info-column {
      display: flex;
      flex-direction: column;
      gap: 20px;

      .info-item {
        background: #fafafa;
        padding: 12px 16px;
        border-radius: 8px;
        border: 1px solid #f0f0f0;

        .info-label {
          font-size: 13px;
          color: #666;
          margin-bottom: 8px;
        }

        .info-content {
          font-size: 14px;
          color: #666;

          .switch-wrapper {
            display: flex;
            align-items: center;
            gap: 6px;

            .switch-text {
              font-size: 13px;
              color: #666;
            }
          }
        }
      }
    }
  }
}

// 报名人群与价格卡片样式
.audience-card {
  .audience-grid {
    padding: 20px;

    .audience-header {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
      margin-bottom: 24px;
    }

    .audience-body {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
    }

    .audience-section {
      background: #fafafa;
      padding: 16px;
      border-radius: 8px;
      border: 1px solid #f0f0f0;

      .audience-label {
        font-size: 13px;
        color: #666;
        margin-bottom: 12px;
      }

      &.price-section {
        .price-display {
          display: flex;
          align-items: baseline;
          gap: 4px;
          
          .currency {
            font-size: 16px;
            color: #262626;
          }
          
          .price-value {
            font-size: 24px;
            font-weight: 500;
            color: #262626;
          }
        }

        .price-input {
          width: 100%;
        }
      }

      &.max-participants-section {
        .max-participants {
          display: flex;
          align-items: baseline;
          gap: 4px;

          .participant-value {
            font-size: 24px;
            font-weight: 500;
            color: #262626;
          }

          .participant-unit {
            font-size: 14px;
            color: #666;
          }
        }

        .participants-input {
          width: 100%;
        }
      }

      &.city-section,
      &.audience-type-section {
        .city-list,
        .audience-info {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
        }

        .city-select,
        .audience-select {
          width: 100%;
        }
      }
    }
  }
}

// 标签与特色卡片样式
.meta-card {
  .meta-grid {
    padding: 20px;

    .meta-section {
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      .meta-title {
        font-size: 13px;
        color: #666;
        margin-bottom: 12px;
      }

      .tag-list {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
      }

      .tag-editor {

        .tag-selector {
          width: 100%;

          .el-select {
            width: 100%;
          }
        }
      }
    }
  }
}

// 图片卡片样式
.images-card {
  .images-wrapper {
    padding: 20px;
  }

  .image-section {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .cover-grid,
  .carousel-grid,
  .poster-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }

  .label-cn {
    font-size: 13px;
    color: #666;
    margin-bottom: 12px;
    direction: ltr;
  }

  .label-ug {
    font-size: 13px;
    color: #666;
    margin-bottom: 12px;
    direction: rtl;
  }

  .image-container {
    position: relative;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;

    :deep(.el-image) {
      width: 100%;
      height: 100%;
      display: block;

      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
      }
    }

    .image-actions {
      position: absolute;
      top: 8px;
      right: 8px;
      opacity: 0;
      transition: opacity 0.3s;
      z-index: 1;
    }

    &:hover .image-actions {
      opacity: 1;
    }
  }

  .image-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
    gap: 8px;

    .image-item {
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      background-color: #f8f8f8;

      :deep(.el-image) {
        width: 100%;
        height: 100%;
        display: block;

        img {
          width: 100%;
          height: 100%;
          object-fit: fill;
        }
      }

      .image-actions {
        position: absolute;
        top: 8px;
        right: 8px;
        opacity: 0;
        transition: opacity 0.3s;
        z-index: 1;
      }

      &:hover .image-actions {
        opacity: 1;
      }
    }

    .carousel-shape {
      aspect-ratio: 15/10;
    }

    .poster-shape {
      aspect-ratio: 8/22;
    }
  }
}

.cover-shape {
  aspect-ratio: 16/10;
}

.upload-box {
  border-radius: 8px;
  overflow: hidden;
  background-color: #fafafa;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f5f5f5;
  }

  .image-uploader {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .el-icon-plus {
    font-size: 20px;
    color: #8c939d;
    margin-bottom: 8px;
  }

  .upload-text {
    font-size: 12px;
    color: #666;
  }
}

.el-button {
  padding: 10px 20px;
  transition: all 0.2s ease;

  &--text {
    padding: 4px 8px;
    font-size: 13px;

    &:hover {
      background: rgba(24, 144, 255, 0.06);
      color: #1890ff;
    }
  }
}

.el-tag {
  margin: 0;
  padding: 0 10px;
}

.el-input :deep(.el-input__inner) {
  font-family: inherit;
}

.el-textarea :deep(.el-textarea__inner) {
  font-family: inherit;
}
</style>
