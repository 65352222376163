import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import request from './utils/request.js'
import 'element-ui/lib/theme-chalk/index.css';
import './utils/element.js'
import './assets/global.css'

Vue.prototype.$http = request

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
