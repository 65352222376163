<template>
  <div class="login_container">
    <div class="login_content">
      <!-- 左侧装饰区域 -->
      <div class="decoration_area">
        <div class="decoration_content">
          <h1 class="brand_title">Art-Lens</h1>
          <p class="slogan">创意无限 • 艺术视界</p>
          <div class="decoration_svg">
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
              <path fill="rgba(255,255,255,0.1)" d="M45,-51.5C59.1,-42.3,71.7,-29.2,76.3,-13.2C80.9,2.8,77.5,21.7,67.8,36.1C58.1,50.5,42,60.4,25.1,64.8C8.1,69.2,-9.8,68,-26.1,62C-42.4,56,-57.1,45.2,-65.2,30.3C-73.4,15.4,-75,-3.7,-69.7,-20.4C-64.4,-37.1,-52.1,-51.4,-37.8,-60.5C-23.4,-69.6,-7,-73.5,4.9,-69.1C16.8,-64.7,30.9,-60.7,45,-51.5Z" transform="translate(100 100)" />
            </svg>
          </div>
        </div>
      </div>

      <!-- 右侧登录区域 -->
      <div class="login_box">
        <div class="login_header">
          <h2>后台管理系统</h2>
          <p>上海阿特兰斯文化传媒有限公司</p>
        </div>

        <el-form ref="loginFormRef" :model="loginForm" :rules="loginFormRules" @keyup.native.enter="login">
          <el-form-item prop="username">
            <el-input 
              v-model="loginForm.username"
              clearable
              placeholder="请输入手机号"
              prefix-icon="el-icon-user">
            </el-input>
          </el-form-item>

          <el-form-item prop="password">
            <el-input 
              v-model="loginForm.password"
              show-password
              type="password"
              placeholder="请输入密码"
              prefix-icon="el-icon-lock">
            </el-input>
          </el-form-item>

          <el-form-item class="btns">
            <el-button type="primary" @click="login" class="login_btn">登录</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="footer_info">
      <span>© 2024 Art-Lens. All rights reserved.</span>
      <a href="http://www.beian.miit.gov.cn" target="_blank">沪ICP备2024104186号</a>
    </div>
  </div>
</template>

<script>
import { setToken } from '@/utils/auth'
import { checkMobile } from '@/utils/validation'

export default {
    name: 'Login',
    data(){
      return {
        loginForm: {
          username: '',
          password: ''
        },
        loginFormRules: {
          username: [
            { required: true, message: '请输入手机号', trigger: 'blur' },
            { validator: checkMobile, trigger: 'blur' }
          ],
          password: [
            { required: true, message: '请输入密码', trigger: 'blur' }
          ]
        }
      }
    },
    methods: {
      login(){
        this.$refs.loginFormRef.validate(valid => {
          if(!valid) return
          this.$http({
            method: 'post',
            url: 'login/access-token',
            data: this.loginForm,
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
          }).then(res => {
            this.$message.success("登录成功");
            setToken(res.access_token)
            this.$router.push('/admin')
          }).catch(err => {
            this.$message.error(err.response?.data?.detail || '登录失败')
          })
        });
      }
    }
}
</script>

<style scoped>
.login_container {
  min-height: 100vh;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login_content {
  display: flex;
  background: rgba(255, 255, 255, 0.95);
  border-radius: 20px;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 900px;
  height: 550px;
  margin-top: 40px;
  backdrop-filter: blur(10px);
}

/* 优化左侧装饰区域 */
.decoration_area {
  flex: 1;
  background: linear-gradient(45deg, #667eea 0%, #764ba2 100%);
  padding: 60px;
  position: relative;
  overflow: hidden;
}

.decoration_content {
  position: relative;
  z-index: 1;
}

.brand_title {
  font-size: 3.5em;
  margin-bottom: 20px;
  font-weight: 700;
  color: white;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.2);
}

.slogan {
  font-size: 1.4em;
  color: rgba(255,255,255,0.9);
}

/* 添加动画SVG样式 */
.decoration_svg {
  position: absolute;
  bottom: -50px;
  right: -50px;
  width: 300px;
  height: 300px;
  animation: rotate 20s linear infinite;
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/* 优化右侧登录区域 */
.login_box {
  flex: 1;
  padding: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login_header {
  text-align: center;
  margin-bottom: 50px;
}

.login_header h2 {
  font-size: 2em;
  color: #333;
  margin-bottom: 15px;
  font-weight: 600;
}

.login_header p {
  color: #666;
  font-size: 1.1em;
}

/* 优化表单样式 */
:deep(.el-input__inner) {
  height: 50px;
  background: #f8f9fa;
  border-radius: 10px;
  transition: all 0.3s ease;
}

:deep(.el-input__inner:focus) {
  background: white;
  border-color: #667eea;
  box-shadow: 0 0 0 3px rgba(102,126,234,0.2);
}

.login_btn {
  width: 100%;
  padding: 12px 0;
  font-size: 16px;
  border-radius: 10px;
  margin-top: 20px;
  height: 50px;
  background: linear-gradient(45deg, #667eea, #764ba2);
  border: none;
  transition: transform 0.3s ease;
}

.login_btn:hover {
  transform: translateY(-2px);
}

.footer_info {
  padding: 15px 0;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
  margin-top: 30px;
}

.footer_info span {
  margin: 0 15px;
}

.footer_info a {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer_info a:hover {
  font-size: 14px;
  font-weight: normal;
  color: rgba(255, 255, 255, 0.7);
}
</style>