<template>
  <div class="banner-create">
    <!-- 顶部操作栏 -->
    <div class="action-bar">
      <div class="left-actions">
        <el-button icon="el-icon-back" size="medium" plain @click="goBanners">返回列表</el-button>
      </div>
      <div class="right-actions">
        <el-button type="primary" icon="el-icon-check" size="medium" @click="submitForm">创建轮播</el-button>
      </div>
    </div>

    <!-- 主要内容区域 -->
    <div class="main-content">
      <div class="content-grid">
        <!-- 左侧内容列 -->
        <div class="left-column">
          <!-- 标题卡片 -->
          <el-card class="title-card">
            <div class="card-header">
              <span>详情标题</span>
            </div>
            <div class="title-grid">
              <div class="title-cn">
                <div class="title-label">中文标题</div>
                <el-input v-model="bannerForm.title_cn" placeholder="请输入中文标题"></el-input>
              </div>
              <div class="title-ug">
                <div class="title-label">ئۇيغۇرچە تېمىسى</div>
                <el-input v-model="bannerForm.title_ug" placeholder="ئۇيغۇرچە تېمىسىنى كىرگۈزۈڭ"></el-input>
              </div>
            </div>
          </el-card>

          <!-- 内容卡片 -->
          <el-card class="desc-card">
            <div class="card-header">
              <span>详情内容</span>
            </div>
            <div class="desc-grid">
              <div class="desc-cn">
                <div class="desc-label">中文内容</div>
                <el-input
                  type="textarea"
                  v-model="bannerForm.content_cn"
                  :rows="4"
                  placeholder="请输入中文内容">
                </el-input>
              </div>
              <div class="desc-ug" style="margin-top: 20px;">
                <div class="desc-label">ئۇيغۇرچە مەزمۇنى</div>
                <el-input
                  type="textarea"
                  v-model="bannerForm.content_ug"
                  :rows="4"
                  placeholder="ئۇيغۇرچە مەزمۇنىنى كىرگۈزۈڭ">
                </el-input>
              </div>
            </div>
          </el-card>

          <!-- 基本信息卡片 -->
          <el-card class="info-card">
            <div class="card-header">
              <span>轮播信息</span>
            </div>
            <div class="info-grid">
              <div class="info-row">
                <div class="info-item">
                  <div class="info-label">排序顺序</div>
                  <el-select v-model="bannerForm.is_primary_image" placeholder="请选择图片排序">
                    <el-option :value="true" label="首图"></el-option>
                    <el-option :value="false" label="非首图"></el-option>
                  </el-select>
                </div>

                <div class="info-item">
                  <div class="info-label">显示状态</div>
                  <el-select v-model="bannerForm.status" placeholder="请选择显示状态">
                    <el-option :value="1" label="显示"></el-option>
                    <el-option :value="0" label="隐藏"></el-option>
                  </el-select>
                </div>
              </div>

              <div class="info-item">
                <div class="info-label">跳转链接<span class="info-tip">（选填，留空则跳转至详情）</span></div>
                <el-input 
                  v-model="bannerForm.link_url" 
                  placeholder="请输入跳转链接地址">
                </el-input>
              </div>
            </div>
          </el-card>
        </div>

        <!-- 右侧内容列 -->
        <div class="right-column">
          <!-- 图片卡片 -->
          <el-card class="images-card">
            <div class="card-header">
              <span>轮播图</span>
            </div>
            <div class="images-wrapper">
              <!-- 封面图 -->
              <div class="image-section">
                <div class="cover-grid">
                  <div class="cover-item">
                    <div class="label-cn">中文封面</div>
                    <div class="image-container cover-shape">
                      <template v-if="bannerForm.cover_cn">
                        <el-image :src="getImageUrl(bannerForm.cover_cn)">
                        </el-image>
                        <div class="image-actions">
                          <el-button 
                            type="danger" 
                            icon="el-icon-delete" 
                            circle 
                            size="mini" 
                            @click="removeImage('cover_cn')">
                          </el-button>
                        </div>
                      </template>
                      <template v-else>
                        <div class="upload-box cover-shape">
                          <el-upload
                            class="image-uploader"
                            :action="getUploadUrl('cover_cn')"
                            :headers="getHeaders()"
                            :show-file-list="false"
                            :on-success="(res) => uploaded(res, 'cover_cn')"
                            :on-error="uploadError"
                            :before-upload="beforeUpload">
                            <i class="el-icon-plus"></i>
                            <div class="upload-text">上传封面</div>
                          </el-upload>
                        </div>
                      </template>
                    </div>
                  </div>
                  
                  <div class="cover-item">
                    <div class="label-ug">ئۇيغۇرچە مۇقاۋا</div>
                    <div class="image-container cover-shape">
                      <template v-if="bannerForm.cover_ug">
                        <el-image :src="getImageUrl(bannerForm.cover_ug)">
                        </el-image>
                        <div class="image-actions">
                          <el-button 
                            type="danger" 
                            icon="el-icon-delete" 
                            circle 
                            size="mini" 
                            @click="removeImage('cover_ug')">
                          </el-button>
                        </div>
                      </template>
                      <template v-else>
                        <div class="upload-box cover-shape">
                          <el-upload
                            class="image-uploader"
                            :action="getUploadUrl('cover_ug')"
                            :headers="getHeaders()"
                            :show-file-list="false"
                            :on-success="(res) => uploaded(res, 'cover_ug')"
                            :on-error="uploadError"
                            :before-upload="beforeUpload">
                            <i class="el-icon-plus"></i>
                            <div class="upload-text">مۇقاۋىنى چىقىرىڭ</div>
                          </el-upload>
                        </div>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-card>
          <!-- 详情图片列表 -->
          <el-card class="carousel-images-card">
            <div class="card-header">
                <span>详情图片</span>
            </div>
            <div class="images-wrapper">
                <div class="image-list">
                <div v-for="(url, index) in bannerForm.images" 
                        :key="index" 
                        class="image-item carousel-shape">
                    <el-image :src="getImageUrl(url)"></el-image>
                    <div class="image-actions">
                    <el-button 
                        type="danger" 
                        icon="el-icon-delete" 
                        circle 
                        size="mini"
                        @click="removeImage('images', index)">
                    </el-button>
                    </div>
                </div>
                <div class="upload-box carousel-shape">
                    <el-upload
                    class="image-uploader"
                    :action="getUploadUrl('linked_images')"
                    :headers="getHeaders()"
                    :show-file-list="false"
                    :on-success="(res) => uploaded(res, 'images')"
                    :on-error="uploadError"
                    :before-upload="beforeUpload">
                    <i class="el-icon-plus"></i>
                    <div class="upload-text">上传详情图片</div>
                    </el-upload>
                </div>
                </div>
            </div>
            </el-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from '@/utils/auth'
import { BASE_URL } from '@/utils/urls'

export default {
  name: 'BannerCreate',
  data() {
    return {
      serverURL: BASE_URL,
      bannerForm: {
        title_cn: '',
        title_ug: '',
        content_cn: '',
        content_ug: '',
        images: [],
        cover_cn: '',
        cover_ug: '',
        is_primary_image: false,
        link_url: '',
        status: 1
      }
    }
  },
  methods: {
    goBanners() {
      this.$router.push('/admin/banners')
    },

    getUploadUrl(type) {
      return `${this.serverURL}/api/v1/images/image/banner/${type}`
    },

    getHeaders() {
      return {
        'Authorization': `Bearer ${getToken()}`
      }
    },

    getImageUrl(path) {
      return `${this.serverURL}${path}`
    },

    beforeUpload(file) {
      const isImage = /^image\/(jpeg|png|gif|jpg)$/.test(file.type)
      const isLt10M = file.size / 1024 / 1024 < 10

      if (!isImage) {
        this.$message.error('只能上传 JPG/PNG/GIF 格式的图片!')
        return false
      }
      if (!isLt10M) {
        this.$message.error('图片大小不能超过 10MB!')
        return false
      }
      return true
    },

    uploaded(response, type) {
      if (!response.data) {
        this.$message.error('上传失败')
        return
      }

      const imageUrl = response.data
      
      if (type === 'images') {
        this.bannerForm.images.push(imageUrl)
      } else {
        this.bannerForm[type] = imageUrl
      }
    },

    uploadError(err) {
      this.$message.error('图片上传失败')
    },

    removeImage(type, index) {
      if (type === 'images') {
        this.bannerForm.images.splice(index, 1)
      } else {
        this.bannerForm[type] = ''
      }
    },

    validateForm() {
      if (!this.bannerForm.title_cn || !this.bannerForm.title_ug) {
        this.$message.error('请填写轮播标题')
        return false
      }

      if (!this.bannerForm.cover_cn || !this.bannerForm.cover_ug) {
        this.$message.error('请上传封面')
        return false
      }

      return true
    },

    async submitForm() {
      if (!this.validateForm()) return

      try {
        const { data: res } = await this.$http.post('/banners/banners', this.bannerForm)
        
        if (res.banner_id) {
          this.$message.success('轮播创建成功')
          this.$router.push('/admin/banners')
        } else {
          this.$message.error('轮播创建失败')
        }
      } catch (err) {
        this.$message.error(err.response?.data?.detail || '轮播创建失败')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.banner-create {
  min-height: 100vh;

  .content-grid {
    display: grid;
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    gap: 20px;
    margin: 0 auto;

    @media screen and (max-width: 1200px) {
      grid-template-columns: 1fr;
      
      .right-column {
        order: 2;
      }
      
      .left-column {
        order: 1;
        transform: translateY(20px);
      }
    }
  }
}

.el-card {
  &.title-card,
  &.desc-card,
  &.info-card,
  &.meta-card,
  &.images-card,
  &.carousel-images-card {
    margin-bottom: 20px;
    border-radius: 8px;

    .card-header {
      position: relative;
      padding: 8px 20px;
      border-radius: 8px;
      background: #fafafa;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        font-size: 14px;
        font-weight: 500;
        color: #1f2937;
        padding-left: 10px;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 2px;
          height: 12px;
          background: #1890ff;
          border-radius: 1px;
        }
      }
    }
  }
}

// 顶部操作栏样式
.action-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  background: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
  margin-bottom: 20px;
  border-radius: 8px;
}

// 标题卡片样式
.title-card {
  .title-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    padding: 20px;

    .title-cn, .title-ug {
      .title-label {
        font-size: 13px;
        color: #666;
        margin-bottom: 12px;
      }
    }

    .title-ug {
      direction: rtl;

      .title-label {
        text-align: right;
      }

      .el-input__inner {
        direction: rtl;
        text-align: right;
      }
    }
  }
}

// 简介卡片样式
.desc-card {
  .desc-grid {
    padding: 20px;

    .desc-cn, .desc-ug {
      .desc-label {
        font-size: 13px;
        color: #666;
        margin-bottom: 12px;
      }
    }

    .desc-ug {
      direction: rtl;

      .desc-label {
        text-align: right;
      }

      .el-textarea__inner {
        direction: rtl;
        text-align: right;
      }
    }
  }
}

// 基本信息卡片样式
.info-card {
  .info-grid {
    padding: 20px;

    .info-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 24px;
      margin-bottom: 20px;
    }

    .info-item {
      background: #fafafa;
      padding: 12px 16px;
      border-radius: 8px;
      border: 1px solid #f0f0f0;

      .info-label {
        font-size: 13px;
        color: #666;
        margin-bottom: 8px;
      }

      .info-tip {
        font-size: 12px;
        color: #999;
      }

      .el-select {
        width: 100%;
      }
    }
  }
}

// 图片卡片样式
.images-card {
  .images-wrapper {
    padding: 20px;
  }

  .image-section {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .cover-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }

  .label-cn {
    font-size: 13px;
    color: #666;
    margin-bottom: 12px;
    direction: ltr;
  }

  .label-ug {
    font-size: 13px;
    color: #666;
    margin-bottom: 12px;
    direction: rtl;
  }

  .image-container {
    position: relative;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;

    .image-actions {
      position: absolute;
      top: 8px;
      right: 8px;
      opacity: 0;
      transition: opacity 0.3s;
      z-index: 1;
    }

    &:hover .image-actions {
      opacity: 1;
    }
  }

  .image-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
    gap: 8px;

    .image-item {
      position: relative;
      border-radius: 8px;
      overflow: hidden;
      background-color: #f8f8f8;

      .image-actions {
        position: absolute;
        top: 8px;
        right: 8px;
        opacity: 0;
        transition: opacity 0.3s;
        z-index: 1;
      }

      &:hover .image-actions {
        opacity: 1;
      }
    }
  }
}

// 详情图片卡片样式
.carousel-images-card {
  margin-top: 20px;
  
  .images-wrapper {
    padding: 20px;
  }
}

.cover-shape {
  aspect-ratio: 16/9;
}

.carousel-shape {
  aspect-ratio: 16/10;
}

.upload-box {
  border-radius: 8px;
  overflow: hidden;
  background-color: #fafafa;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #f5f5f5;
  }

  .image-uploader {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .el-icon-plus {
    font-size: 20px;
    color: #8c939d;
    margin-bottom: 8px;
  }

  .upload-text {
    font-size: 12px;
    color: #666;
  }
}

.el-button {
  padding: 10px 20px;
  transition: all 0.2s ease;

  &--text {
    padding: 4px 8px;
    font-size: 13px;

    &:hover {
      background: rgba(24, 144, 255, 0.06);
      color: #1890ff;
    }
  }
}

.el-input :deep(.el-input__inner) {
  font-family: inherit;
}

.el-textarea :deep(.el-textarea__inner) {
  font-family: inherit;
}
</style>
