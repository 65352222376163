<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>轮播管理</el-breadcrumb-item>
      <el-breadcrumb-item>轮播列表</el-breadcrumb-item>
    </el-breadcrumb>

    <!-- 卡片 -->
    <el-card>
      <!-- 搜索区域 -->
      <el-row :gutter="10">
        <el-col :span="6">
          <el-input 
            placeholder="请输入轮播标题" 
            v-model="searchQuery.title_cn" 
            clearable 
            @clear="fetchBanners()" 
            size="medium">
            <el-button slot="append" icon="el-icon-search" @click="fetchBanners()"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-select v-model="searchQuery.status" placeholder="显示状态" @change="fetchBanners()" size="medium">
            <el-option :value="1" label="显示"></el-option>
            <el-option :value="0" label="隐藏"></el-option>
          </el-select>
        </el-col>
        <el-col :span="2">
          <el-button type="default" @click="resetSearch()" size="medium">重置</el-button>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="createBanner()" size="medium">创建轮播</el-button>
        </el-col>
      </el-row>

      <!-- 轮播列表 -->
      <el-table :data="bannerList" style="width: 100%" border stripe>
        <el-table-column label="#" type="index" width="50" align="center"></el-table-column>
        <el-table-column label="标题" prop="title_cn" align="center"></el-table-column>
        <el-table-column label="封面图" width="180" align="center">
          <template slot-scope="scope">
            <el-image class="cover-image" :src="`${serverURL}${scope.row.cover_cn}`" :preview-src-list="[`${serverURL}${scope.row.cover_cn}`]" fit="cover"></el-image>
          </template>
        </el-table-column>
        <el-table-column label="跳转类型" width="120" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.link_url ? 'primary' : 'success'">
              {{ scope.row.link_url ? '跳转链接' : '跳转详情' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="100" align="center">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status === 1 ? 'success' : 'info'">
              {{ scope.row.status === 1 ? '显示' : '隐藏' }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-button type="primary" size="medium" @click="viewBanner(scope.row.banner_id)">查看详情</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 -->
      <el-pagination
        @size-change="onSizeChange"
        @current-change="onPageChange"
        :current-page="pageNum"
        :page-sizes="[1, 2, 5, 10]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import { BASE_URL } from '@/utils/urls'

export default {
  name: 'Banners',
  data() {
    return {
      serverURL: BASE_URL,
      bannerList: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      searchQuery: {
        status: null,
        title_cn: ''
      }
    }
  },
  created() {
    this.fetchBanners()
  },
  methods: {
    fetchBanners() {
      this.$http({
        method: 'get',
        url: 'banners/banners',
        params: {
          ...this.searchQuery,
          page: this.pageNum,
          size: this.pageSize
        }
      }).then(res => {
        if (!res.success) return this.$message.error(res.message)
        this.bannerList = res.data
        this.total = res.total
      }).catch(err => {
        this.$message.error(err.response?.data?.detail || '获取轮播列表失败')
      })
    },

    resetSearch() {
      this.searchQuery = { 
        status: null,
        title_cn: ''
      }
      this.fetchBanners()
    },

    onSizeChange(newSize) {
      this.pageSize = newSize
      this.fetchBanners()
    },

    onPageChange(newPage) {
      this.pageNum = newPage
      this.fetchBanners()
    },

    viewBanner(id) {
      this.$router.push(`/admin/banner/details/${id}`)
    },

    createBanner() {
      this.$router.push('/admin/banner/create')
    }
  }
}
</script>

<style scoped>
.el-card {
  margin-top: 15px;
}
.el-table {
  margin-top: 15px;
}
.el-pagination {
  margin-top: 15px;
}

.cover-image {
  width: 150px;
  height: 80px;
  border-radius: 5px;
}
</style>
